.email-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.email-modal {
  background-color: white;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent content from overflowing */
}

.email-modal h2 {
  margin: 0;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.email-subject-input {
  width: 90% !important;
  padding: 10px;
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.email-editor-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 20px;
}

.quill-editor {
  height: 100%;
}

.quill-editor .ql-container {
  font-size: 16px;
}

/* Limit image size */
.quill-editor .ql-editor img {
  max-width: 100%;
  height: auto;
}

.email-modal-buttons {
  display: flex;
  justify-content: center; /* Center the buttons */
  gap: 10px; /* Smaller gap between buttons */
  padding: 20px;
  background-color: white;
  margin: 0 auto; /* Center the button container */
  width: fit-content; /* Make container only as wide as buttons */
}

.send-btn,
.cancel-email-btn {
  flex: 0 0 100px; /* Fixed width without growing or shrinking */
  height: 36px !important;  /* Slightly smaller height */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.send-btn {
  background-color: #4CAF50;
  color: white;
}

.send-btn:hover {
  background-color: #45a049;
}

.cancel-btn {
  background-color: #f44336;
  color: white;
}

.cancel-btn:hover {
  background-color: #d32f2f;
}

/* Quill specific styles */
.ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fff;
}

input[type="text"], input[type="number"], input[type="url"], textarea {
  width: 90%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  margin-bottom: 5px;
}