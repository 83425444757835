.loyalty-card {
  background-color: #f8f9fa;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

@media (min-width: 600px) {
  .loyalty-card {
    max-width: 550px;
  }
}

.program-name {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 15px;
  text-align: center;
}

.stamps-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
}

.stamp {
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stamp.active {
  background-color: #27ae60;
  color: white;
  transform: scale(1.1);
}

@media (max-width: 400px) {
  .stamps-container {
    gap: 10px;
  }
  
  .stamp {
    width: 50px;
    height: 50px;
    font-size: 24px;
  }
}

@keyframes stampRedeemed {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.stamp.redeemed {
  animation: stampRedeemed 0.5s ease;
}

.stamp-count, .cards-completed {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  color: #34495e;
  margin-bottom: 10px;
}

.vouchers-title {
  color: #2c3e50;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 15px;
  text-align: center;
}

.vouchers-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.voucher {
  background-color: #fff;
  border: 2px dashed #3498db;
  border-radius: 10px;
  padding: 15px;
  transition: all 0.3s ease;
}

.voucher:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.voucher.redeemed {
  border-color: #95a5a6;
  opacity: 0.7;
}

.voucher.available {
  border-color: #27ae60;
}

.voucher-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.voucher-id, .voucher-status, .voucher-redeemed-date, .voucher-created-date {
  margin: 0;
  font-size: 14px;
  color: #34495e;
}

.voucher-status {
  font-weight: bold;
  color: #27ae60;
}

.voucher.redeemed .voucher-status {
  color: #95a5a6;
}

.no-vouchers {
  text-align: center;
  color: #7f8c8d;
  font-style: italic;
}

.loyalty-card button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.loyalty-card button:hover {
  background-color: #2980b9;
}

.loyalty-card .qr-reader {
  margin-top: 20px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.loyalty-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  padding-top: max(10px, env(safe-area-inset-top));
}

.back-button,
.redeem-visit-button {
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

@supports (-webkit-touch-callout: none) {
  .back-button {
    /* Make touch target larger for iOS */
    padding: 12px 20px;
    min-height: 44px;
  }
}

.back-button:hover,
.redeem-visit-button:hover {
  background-color: none !important ;
}

.qr-scanner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  /* iOS-specific fixes */
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  padding-top: env(safe-area-inset-top);
}

/* .qr-scanner-container video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
} */

.qr-scanner-container video {
  max-width: 100%;
  max-height: 70vh;
}


.qr-scanner-container button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.qr-scanner-container button:hover {
  background-color: #c0392b;
}

.about-button {
  display: block;
  margin: 15px auto;
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.about-button:hover {
  background-color: #2980b9;
}

.about-section {
  margin-top: 15px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

#capture-visit-button {
  display: block;
  width: 170px;
  padding: 12px 15px;
  background-color: #2ecc71; /* Green color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin: 0 auto;
  transition: background-color 0.3s ease;
}

#capture-visit-button:hover {
  background-color: #27ae60; /* Darker green on hover */
}

#capture-visit-button:active {
  transform: scale(0.98);
}

/* loading overlay and success*/

/* Loader Styles */
.loading-overlay-card {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loader-card {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Message Styles */
.message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeInOut 3s ease forwards;
}

.message.success {
  background-color: #4caf50;
}

.message.error {
  background-color: #f44336;
}

.message.info {
  background-color: #cce5ff;
  color: #004085;
}



@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Message Styles */

/* Add new iOS camera fallback styles */
.ios-camera-fallback {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1001;
  padding: 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  width: 80%;
  max-width: 300px;
}

.ios-camera-fallback p {
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.4;
}

.ios-camera-fallback button {
  margin-top: 15px;
  padding: 10px 20px;
  background: #fff;
  color: #000;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

/* Add new iOS camera fallback styles */

/* Update scanner close button */
.close-scanner-btn {
  position: absolute;
  top: max(20px, env(safe-area-inset-top)); /* iOS notch safety */
  right: 20px;
  z-index: 1001;
  padding: 12px 24px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  color: #000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  min-height: 44px; /* iOS touch target size */
}

.close-scanner-btn:active {
  background: rgba(255, 255, 255, 1);
}

/* iOS-specific adjustments */
@supports (-webkit-touch-callout: none) {
  .qr-scanner-container {
    /* Additional iOS safety area */
    padding-top: max(env(safe-area-inset-top), 20px);
    padding-bottom: env(safe-area-inset-bottom);
  }
  
  .close-scanner-btn {
    top: max(env(safe-area-inset-top) + 10px, 30px);
  }
}

/* Make touch targets larger on iOS */
@media (hover: none) and (pointer: coarse) {
  .close-scanner-btn {
    padding: 14px 28px;
  }
  
  .ios-camera-fallback button {
    padding: 12px 24px;
    min-height: 44px;
  }
}

.camera-fallback {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  color: white;
  width: 80%;
  max-width: 300px;
  z-index: 1001;
}

.retry-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  margin: 10px;
  cursor: pointer;
  min-height: 44px; /* iOS touch target */
}

.retry-button:active {
  background-color: #45a049;
}

/* iOS adjustments */
@supports (-webkit-touch-callout: none) {
  .camera-fallback button {
    min-height: 44px;
    padding: 12px 24px;
  }
}

.pin-redeem-button {
  color: #3498db;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
}

.pin-redeem-button:hover {
  background: none;
}



.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 
  10px;
}

.photo-capture-ui {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.photo-input {
  margin: 15px 0;
}