.reporting-tab {
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .report-section {
    margin-bottom: 30px;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 6px;
  }
  
  .report-settings-section {
    margin-bottom: 30px;
    padding: 20px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .settings-form {
    max-width: 600px;
  }
  
  .setting-group {
    margin-bottom: 20px;
  }
  
  .email-list {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .email-tag {
    background: #e9ecef;
    padding: 5px 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .email-tag button {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 0 5px;
  }
  
  .report-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin: 20px 0;
  }
  
  .report-table th {
    background: #f1f3f5;
    padding: 12px;
    text-align: left;
    font-weight: 600;
    border-bottom: 2px solid #dee2e6;
    background-color: #3498db;
  }
  
  .report-table td {
    padding: 12px;
    border-bottom: 1px solid #e9ecef;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
  }
  
  .pagination button {
    padding: 8px 16px;
    border: 1px solid #dee2e6;
    background: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .daily-summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    margin: 20px 0;
  }
  
  .daily-stat {
    background: white;
    padding: 15px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  }
  
  .checkin-count {
    font-weight: bold;
    color: #2196F3;
  }

  .registration-count{
    font-weight: bold;
    color: #2196F3;
  }
  
  .loading-indicator {
    text-align: center;
    padding: 20px;
    color: #666;
  }
  
  .error-message {
    color: #dc3545;
    padding: 10px;
    background: #f8d7da;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .settings-message {
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
  }
  
  .settings-message.success {
    background: #d4edda;
    color: #155724;
  }
  
  .settings-message.error {
    background: #f8d7da;
    color: #721c24;
  }


/* Loading states */
.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #666;
  }
  
  /* No data states */
  .no-data {
    text-align: center;
    padding: 20px;
    color: #666;
    background: #f8f9fa;
    border-radius: 4px;
    margin: 10px 0;
  }
  
  /* Error boundary fallback */
  .error-fallback {
    padding: 20px;
    margin: 10px 0;
    background: #f8d7da;
    border-radius: 4px;
    color: #721c24;
    text-align: center;
  }
  
  /* Switch toggle for enable/disable */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Email form improvements */
  .add-email-form {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    width: 60%;
  }
  
  .add-email-form input {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
  }
  
  .add-email-form button {
    padding: 8px 16px;
    background: #2196F3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-email-form button:hover {
    background: #1976d2;
  }
  
  /* Save settings button */
  .save-settings-btn {
    background: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    width: 40%;
  }
  
  .save-settings-btn:disabled {
    background: #cccccc;
    cursor: not-allowed;
  }
  
  .save-settings-btn:hover:not(:disabled) {
    background: #218838;
  }
  
  /* Better table responsiveness */
  @media (max-width: 768px) {
    .report-table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  
    .daily-summary {
      grid-template-columns: 1fr;
    }
  }
  
  /* Animation for messages */
  .settings-message {
    animation: fadeIn 0.3s ease-in;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .pagination button {
    padding: 8px 12px;
    background-color: #636363;
  }

  .stats-overview {
    margin-bottom: 30px;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .stat-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center;
  }
  
  .stat-card h3 {
    margin: 0 0 10px 0;
    color: #666;
    font-size: 0.9rem;
  }
  
  .stat-number {
    font-size: 1.8rem;
    font-weight: bold;
    color: #3498db;
    margin: 0;
  }
  
  .report-table {
    width: 100%;
    margin-top: 15px;
    border-collapse: collapse;
  }
  
  .report-table th {
    padding: 12px;
    text-align: left;
  }
  
  .report-table td {
    padding: 12px;
    border-bottom: 1px solid #eee;
  }
  
  @media (max-width: 768px) {
    .stats-grid {
      grid-template-columns: 1fr;
    }
    
    .report-table {
      font-size: 0.9rem;
    }
  }