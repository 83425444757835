.notification-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .notification-popup {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 400px;
    text-align: center;
  }
  
  .notification-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .allow-btn, .block-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .allow-btn {
    background-color: #4CAF50;
    color: white;
  }
  
  .allow-btn:hover {
    background-color: #45a049;
  }
  
  .block-btn {
    background-color: #f44336;
    color: white;
  }
  
  .block-btn:hover {
    background-color: #da190b;
  }

  .notification-popup .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
    color: #666;
    line-height: 1;
  }
  
  .notification-popup .close-button:hover {
    color: #333;
  }
  