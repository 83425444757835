/* Container and overlay */
.login-modal-wrapper .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-modal-wrapper .modal-dialog {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px; /* Fixed width to match UI */
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Modal content */
.login-modal-wrapper .modal-header {
  margin-bottom: 20px;
}

.login-modal-wrapper .modal-header h2 {
  margin: 0 0 20px;
  color: #333;
  font-size: 20px; /* Smaller font size */
  text-align: center;
}

.login-modal-wrapper .modal-body {
  width: 100%;
}

/* Form elements */
.login-modal-wrapper .login-form-group {
  margin-bottom: 15px;
}

.login-modal-wrapper .login-input {
  width: calc(100% - 20px); /* Account for padding */
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}


.login-modal-wrapper .login-error-message {
  color: #dc3545;
  margin: 10px 0;
  font-size: 14px;
}

/* Buttons */
.login-modal-wrapper .modal-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.login-modal-wrapper .modal-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.login-modal-wrapper .modal-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Close button */
.login-modal-wrapper .modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #666;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
}

/* Additional options */
.login-modal-wrapper .modal-footer {
  margin-top: 20px;
  text-align: center;
}

.login-modal-wrapper .modal-link {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  text-decoration: none;
}

.login-modal-wrapper .modal-link:hover:not(:disabled) {
  text-decoration: underline;
  color: #0056b3;
}

.login-modal-wrapper .forgot-password {
  margin-top: 10px;
  text-align: center;
  margin-bottom: 15px;
}

.login-modal-wrapper .register-section {
  margin-top: 15px;
  font-size: 14px;
  border-top: 1px solid #eee;
  padding-top: 15px;
}

.login-modal-wrapper .register-text {
  margin-right: 5px;
  color: #666;
}

/* Mobile specific styles */
@media (max-width: 480px) {
  .login-modal-wrapper .modal-dialog {
    width: 280px;
    margin: 10px;
  }

  .login-modal-wrapper .modal-button {
    padding: 10px;
    font-size: 14px;
  }

  .login-modal-wrapper .login-input {
    width: 100%;
  }


}

.password-input-group {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #666;
  font-size: 12px;
}

.password-toggle:hover {
  color: #333;
}