.landing-page {
  /* Update these properties */
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
  

  body#lp1 {
    margin: 0 !important;
    padding: 0 !important;
    background-color: white !important;
    justify-content: center !important;
    align-items: center !important;
    min-height: 100vh !important;
    max-width: 100% !important;
  }

  .container#lp1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    margin: 0 auto;
}

.landing-content,
.features-section,
.benefits-section,
.cta-section,
.featured-vendors {
  width: 100% !important;
  max-width: 100% !important;
  padding: 20px !important;
  margin: 0 !important;
  overflow-x: hidden  !important;
}

h2 {
border-bottom: none;
}
  

  .landing-content {
    width: 100%;
    max-width: 1800px;
    text-align: center;
    margin: 2rem auto;
    padding: 0 20px;
  }
  
  .landing-logo {
    margin: 0 auto 2rem;
    display: block;
  }
  
  .landing-title {
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 1.2;
    text-align: center;
  }
  
  .text-blue {
    color: #4285F4;
  }
  
  .text-dark {
    color: #202124;
  }
  
  .landing-description {
    font-size: 1.25rem;
    color: #5F6368;
    margin-bottom: 2rem;
  }
  
  button.cta-button {
    width: 80%; /* Make the buttons responsive and fit smaller screens */
    max-width: 300px; /* Restrict the maximum width on larger screens */
    margin: 10px 0;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff; /* Match the primary blue color */
    color: white;
    font-size: 1.1em;
    cursor: pointer;
}
  
.cta-button:hover {
    opacity: 0.9; /* Slight change on hover, avoid full transparency */
}


  .landing-footnote {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: #5F6368;
  }
  
  .features-section {
    width: 100%;
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem 20px;
    box-sizing: border-box;
  }
  


  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .feature-card {
    background-color: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .feature-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
    display: block;
  }
  
  .feature-card h3 {
    font-size: 1.25rem;
    color: #202124;
    margin-bottom: 0.5rem;
  }
  
  .feature-card p {
    color: #5F6368;
  }
  
  .brand-image-section {
    width: 100%;
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .brand-image {
    max-width: 100%;
    height: auto;
  }
  
  .cta-section {
    width: 100%;
    background-color: #4285F4;
    color: white;
    text-align: center;
    max-width: 1800px;
    margin: 2rem auto;
    padding: 2rem 20px;
    box-sizing: border-box;
  }
  
  .cta-section h2 {
    color: white;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .cta-section p {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  
  .cta-button-large {
    font-size: 1.25rem;
    padding: 1rem 2rem;
    background-color: white;
    color: #4285F4;
  }
  
  .cta-button-large:hover {
    background-color: #f1f3f4;
  }
  
  .landing-footer {
    width: 100%;
    text-align: center;
    padding: 4rem 2rem;
    box-sizing: border-box;
    background-color: #f8f9fa;
    color: #5F6368;
    margin-left: 0;
    margin-right: 0;
  }
  
  @media (min-width: 640px) {
    .landing-title {
      font-size: 3rem;
      text-align: center;
    }
  }
  
  @media (min-width: 768px) {
    .landing-title {
      font-size: 3.75rem;
      text-align: center;
    }
  }



  /* Testimonials section */
.testimonials {
    margin: 20px auto;
    padding: 15px;
    background-color: #f9f9f9;
    text-align: center;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;
}


  
  .testimonial-card {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
    display: block;
  }
  
  .testimonial-text {
    font-style: italic;
    margin-bottom: 1rem;
  }
  
  .testimonial-author {
    font-weight: bold;
  }


  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.cta-button-primary {
    background-color: #000;
    color: #fff;
}

.cta-button-secondary {
    background-color: #fff !important;
    color: #000 !important;
    border: 1px solid #000;
}

.whatsapp-button-lp {
    display: inline-flex;
    align-items: center;
    background-color: #25D366;
    color: white;
    z-index:9999;
    border-radius: 30px;
    text-decoration: none;
    margin-top: 20px;
    font-weight: bold;
    z-index: 9999;
}

.whatsapp-button-lp img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.lpinput, .lptextarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

 /* Updated Benefits Section */
.benefits-section {
    text-align: center;
    width: 100%;
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem 20px;
    box-sizing: border-box;
    
  }
  
  .benefits-section h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .benefits-section p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 3rem;
  }
  
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .benefit-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .benefit-item:hover {
    transform: translateY(-5px);
  }
  
  .benefit-icon {
    width: 60px;
    height: 60px;
    background-color: #f0f4f8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1rem;
  }
  
  .benefit-icon svg {
    width: 30px;
    height: 30px;
    color: #4285F4;
  }

  .benefit-icon i {
    font-size: 32px;
    color: #4285F4;
  }
  
  .benefit-item h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .benefit-item p {
    font-size: 1rem;
    color: #666;
  }
  
  .whatsapp-button-lp {
    display: inline-flex;
    align-items: center;
    background-color: #25D366;
    color: white;
    z-index: 9999;
    border-radius: 30px;
    text-decoration: none;
    margin-top: 20px;
    font-weight: bold;
  }
  
  .whatsapp-button-lp i {
    font-size: 24px;
    margin-right: 10px;
  }

  .whatsapp-button {
      z-index: 9999;
      border-radius: 25px;
      height: auto;
      padding: 10px 20px;
      width: auto;
  }



  
  .cta-section {
    background-color: #4285F4;
    color: white;
    padding: 4rem 0;
    text-align: center;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .cta-button-primary {
    background-color: white;
    color: #4285F4;
  }
  
  .cta-button-secondary {
    background-color: transparent;
    color: white;
    border: 2px solid white;
  }
  
  .cta-button {
    padding: 12px 24px;
    font-size: 18px;
    border-style: dotted;
    color: #181818;
    font-weight: bold;
    border-color: black;
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .features-section h2 {
    text-align: center;
    border-bottom: none;
  }


  @media (max-width: 768px) {
    .landing-page {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 0;
      text-align: center;
    }
  
    .landing-content {
      width: 100%;
      padding: 0 1rem;
      padding: 20px;
      margin:0
    }
  
    .landing-title {
      font-size: 2rem;
    }
  
    .cta-buttons {
      flex-direction: column;
      align-items: center;
    }
  
    .cta-button {
      width: 100%;
      margin-bottom: 1rem;
    }
  
    .benefits-grid {
      grid-template-columns: 1fr;
    }
  
    .benefit-item {
      padding: 1.5rem;
    }

    .features-section {
        width: 100%;
        max-width: 64rem;
        margin: 0 !important;
        text-align: center;
        padding: 0
      }

      .whatsapp-button {
        align-items: center;
        background-color: #25d366;
        border-radius: 50%;
        bottom: 20px;
        box-shadow: 0 2px 5px #0003;
        color: #fff;
        display: flex;
        height: 32px;
        justify-content: center;
        padding: 5px;
        position: fixed;
        right: 20px;
        text-decoration: none;
        width: 32px;
    }
  }
  
  @media (max-width: 480px) {
    .landing-title {
      font-size: 1.75rem;
    }
  
    .landing-description {
      font-size: 1rem;
    }
  
    .cta-button {
      font-size: 0.9rem;
      padding: 0.6rem 1.2rem;
    }
  
    .benefits-section h2 {
      font-size: 1.75rem;
    }

    .features-section h2 {
      text-align: center;
    }
  
    .benefit-item h3 {
      font-size: 1.25rem;
    }
  
    .benefit-item p {
      font-size: 0.9rem;
    }

    .features-section {
        width: 100%;
        max-width: 64rem;
        margin: 0 !important;
        text-align: center;
        padding: 0
      }

  }

  .loading-container-lp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #fff;
  }
  
  .loading-spinner-lp {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #4285F4;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .featured-vendors {
    background: #fff;
    margin-left: 0;
    margin-right: 0;
    padding: 1rem 2rem;
    box-sizing: border-box;
    max-width: 800px; 
    margin: 0 auto;
    width: 100%;
    text-align: center; 
  }
  
  .vendors-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    width: 100%;
    max-width: 800px;
    margin: 1rem auto;
    padding: 0;
  }

  .vendor-card {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
  }
  
  .vendor-card:hover {
    transform: translateY(-5px);
  }

  .vendor-app-btn {
    background: #4285F4;
    color: white;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 0.9rem;
    margin-top: 10px;
    text-decoration: none;
    align-self: center;
  }
  
  .vendor-logo-lp {
    width: 120px;
    height: 120px;
    object-fit: contain;
    border-radius: 8px;
  }
  
  .vendor-card h3 {
    margin: 0.5rem 0;
    color: #333;
  }
  
  .vendor-card p {
    color: #666;
    font-size: 0.9rem;
    margin: 0;
  }

  .landing-page-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    text-align: center;
  }
  
  .retry-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4285F4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .retry-button:hover {
    background-color: #3367D6;
  }


 
  