.enquiry-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.enquiry-form-container {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    width: 70%;
    max-width: 500px;
    text-align:center;

}

.enquiry-form-container h2 {
    margin-bottom: 1rem;
}

.enquiry-form-container form {
    /* display: flex; */
    flex-direction: column;
}

.enquiry-form-container input,
.enquiry-form-container textarea {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    width: 90%;
    text-align: center;
}

.enquiry-form-container button {
    background-color: #4285F4;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.2s ease;
}





.enquiry-form-container .close-btn {
    position: absolute;
    top: 2px;
    right: -3em;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    padding: 0;
    line-height: 1;
    transition: color 0.3s ease;
}

.enquiry-form-container .close-btn:hover {
    transform: translateY(-3px);
    background-color: none !important;
    color: #f44336;
}

.enquiry-form-container .close-btn:active {
    background-color: none !important;
    transform: translateY(1px);
}

/* Notification styles */
.notification {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    text-align: center;
}

.notification.success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.notification.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.enquiry-form-container {
    position: relative;
}
