.profile-edit-container {
    background: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .profile-edit-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
  }
  
  .profile-edit-header h2 {
    margin: 0;
    color: #333;
  }
  
  .current-info {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .current-info h3 {
    margin-top: 0;
    color: #555;
    font-size: 1.1rem;
    margin-bottom: 15px;
  }
  
  .info-grid {
    display: grid;
    gap: 15px;
  }
  
  .info-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  
  .info-item:last-child {
    border-bottom: none;
  }
  
  .info-item label {
    font-weight: 500;
    color: #666;
  }
  
  .edit-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .edit-btn {
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1rem;
    width: 200px
  }
  
  .edit-btn:hover {
    background-color: #0056b3;
  }
  
  .success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 15px;
    border-radius: 4px;
    text-align: center;
    margin: 20px 0;
  }
  
  @media (max-width: 480px) {
    .profile-edit-container {
      padding: 15px;
    }
  
    .info-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
  }