.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 500px;
  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal textarea {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
  }
  
  .modal button {
    margin-right: 10px;
  }
  
  .btn-small {
    font-size: 0.8rem;
    padding: 5px 10px;
  }