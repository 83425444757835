.subscription-checkout-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 30px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }
  
  .subscription-checkout-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .subscription-checkout-header h1 {
    color: #2c3e50;
    font-size: 2.2rem;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .subscription-details-card {
    background: #f8fafc;
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 30px;
    border: 1px solid #e2e8f0;
  }
  
  .subscription-price {
    font-size: 2.5rem;
    color: #3498db;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .subscription-features {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .subscription-features li {
    padding: 12px 0;
    color: #4a5568;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #edf2f7;
  }
  
  .subscription-features li:last-child {
    border-bottom: none;
  }
  
  .subscription-features li::before {
    content: "✓";
    color: #48bb78;
    margin-right: 12px;
    font-weight: bold;
  }
  
  .subscription-checkout-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .subscription-form-group {
    margin-bottom: 20px;
    width:95%
  }
  
  .subscription-form-group.full-width {
    grid-column: 1 / -1;
  }
  
  .subscription-form-group label {
    display: block;
    margin-bottom: 8px;
    color: #4a5568;
    font-weight: 500;
  }
  
  .subscription-form-group input,
  .subscription-form-group textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .subscription-form-group input:focus,
  .subscription-form-group textarea:focus {
    outline: none;
    border-color: #3498db;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
  }
  
  .subscription-checkout-button {
    grid-column: 1 / -1;
    background-color: #3498db;
    color: white;
    padding: 16px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .subscription-checkout-button:hover {
    background-color: #2980b9;
  }
  
  .subscription-error-message {
    grid-column: 1 / -1;
    padding: 12px;
    background-color: #fed7d7;
    color: #c53030;
    border-radius: 6px;
    margin-top: 20px;
  }

  .subscription-subtitle {
    color: #64748b;
    font-size: 1.1rem;
    margin-top: 8px;
}

.subscription-form-actions {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.secure-payment-note {
    color: #64748b;
    font-size: 0.9rem;
    text-align: center;
}

.button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.spinner {
    width: 20px;
    height: 20px;
    border: 3px solid #ffffff;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.subscription-checkout-button:disabled {
    background-color: #93c5fd;
    cursor: not-allowed;
}

.subscription-error-message {
    grid-column: 1 / -1;
    padding: 12px;
    background-color: #fed7d7;
    color: #c53030;
    border-radius: 6px;
    margin-top: 20px;
    text-align: center;
}

@media (max-width: 640px) {
    .subscription-checkout-container {
        margin: 20px;
        padding: 20px;
    }

    .subscription-checkout-form {
        grid-template-columns: 1fr;
    }

    .subscription-price {
        font-size: 2rem;
    }
}