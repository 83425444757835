.review-management {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    padding: 24px;
    margin: 20px;
}

.review-nav {
    display: flex;
    gap: 16px;
    margin-bottom: 32px;
    border-bottom: 1px solid #edf2f7;
    padding-bottom: 16px;
}

.review-nav button {
    background: none;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    color: #64748b;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}

.review-nav button.active {
    color: #3498db;
    font-weight: 600;
    background-color: transparent !important;
}

.review-nav button.hover {
    background-color: transparent !important;
}

.review-nav button:hover {
    background-color: transparent !important;
}

.review-nav button.active::after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
}

/* Configuration Section */
.review-configuration {
    max-width: 800px;
    margin: 0 auto;
}

.question-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 32px;
}

.question-item {
    display: grid;
    grid-template-columns: 150px 1fr 100px;
    gap: 16px;
    align-items: center;
    padding: 16px;
    background-color: #f8fafc;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
  }
  

  .question-item select,
  .question-item input[type="text"] {
    padding: 10px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }

.question-item select:focus,
.question-item input[type="text"]:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.1);
}

.add-question-btn {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-start;
}

.add-question-btn:hover {
    background-color: #2980b9;
}

/* Reviews Section */
.reviews-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 32px;
}

.stat-card-review {
    background-color: #f8fafc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    border: 1px solid #e2e8f0;
}

.stat-card-review h4 {
    color: #64748b;
    margin-bottom: 8px;
    font-size: 14px;
}

.stat-card-review p {
    color: #1e293b;
    font-size: 24px;
    font-weight: 600;
}

.reviews-table {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.review-item {
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    padding: 20px;
}

.review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid #f1f5f9;
}


.response-item {
    margin-bottom: 16px;
}

.question-text {
    font-weight: 500;
    color: #334155;
    margin-bottom: 8px;
}

.response-text {
    color: #475569;
    line-height: 1.5;
}

.stars {
    color: #f59e0b;
    font-size: 18px;
    letter-spacing: 2px;
}

/* Notification Settings */
.notification-settings-review {
    margin-top: 32px;
    padding-top: 24px;
    border-top: 1px solid #e2e8f0;
}

.notification-settings-review textarea {
    width: 100%;
    min-height: 100px;
    padding: 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    margin-top: 12px;
    font-size: 14px;
    resize: vertical;
}

.save-config-btn {
    background-color: #10b981;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 24px;
}

.save-config-btn:hover {
    background-color: #059669;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .review-management {
        margin: 12px;
        padding: 16px;
    }

    .question-item {
        grid-template-columns: 1fr;
        gap: 12px;
    }

    .reviews-stats {
        grid-template-columns: 1fr;
    }

    .review-nav {
        overflow-x: auto;
        padding-bottom: 12px;
        margin-bottom: 24px;
    }

    .review-nav button {
        padding: 8px 16px;
        font-size: 14px;
        white-space: nowrap;
    }
}

.save-notification {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px 25px;
    border-radius: 4px;
    animation: fadeInOut 3s ease-in-out;
    z-index: 1000;
}

.save-notification.success {
    background-color: #4CAF50;
    color: white;
}

.save-notification.error {
    background-color: #f44336;
    color: white;
}

.question-controls {
    display: flex;
    gap: 5px;
    margin-right: 10px;
}

.move-btn, .delete-btn-rm {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.move-btn {
    background-color: #817070;
}

.move-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.delete-btn-rm {
    background-color: #ff4444;
    color: white;
}

.question-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 4px;
}

@keyframes fadeInOut {
    0% { opacity: 0; transform: translateY(-20px); }
    10% { opacity: 1; transform: translateY(0); }
    90% { opacity: 1; transform: translateY(0); }
    100% { opacity: 0; transform: translateY(-20px); }
}

.question-stats {
    margin: 30px 0;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
}

.question-stats h3 {
    margin-bottom: 20px;
    color: #333;
}

.question-stat-card {
    background: white;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.question-stat-card h4 {
    margin-bottom: 15px;
    color: #444;
}

.rating-stats {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.average-rating {
    display: flex;
    align-items: center;
    gap: 10px;
}

.rating-value {
    font-size: 24px;
    font-weight: bold;
    color: #f8c51c;
}

.response-count {
    color: #666;
    font-size: 14px;
}

.rating-distribution {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rating-bar {
    display: flex;
    align-items: center;
    gap: 10px;
}

.rating-label {
    width: 30px;
    text-align: right;
    color: #666;
}

.rating-bar-container {
    flex: 1;
    height: 20px;
    background: #eee;
    border-radius: 10px;
    overflow: hidden;
}

.rating-bar-fill {
    height: 100%;
    background: #f8c51c;
    transition: width 0.3s ease;
}

.rating-count {
    width: 30px;
    text-align: left;
    color: #666;
}

.text-response-stats {
    color: #666;
    font-style: italic;
}

.review-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.reviewer-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.reviewer-name {
    font-weight: bold;
    font-size: 1.1em;
    color: #333;
    display: flex;
    align-items: center;
    gap: 8px;
}

.registered-badge {
    background-color: #4CAF50;
    color: white;
    font-size: 0.7em;
    padding: 2px 6px;
    border-radius: 10px;
    font-weight: normal;
}

.reviewer-email, .reviewer-phone {
    font-size: 0.9em;
    color: #666;
    display: flex;
    align-items: center;
    gap: 5px;
}

.reviewer-email i, .reviewer-phone i {
    font-size: 0.9em;
    width: 16px;
}

.review-date {
    color: #888;
    font-size: 0.9em;
}

@media (max-width: 600px) {
    .review-header {
        flex-direction: column;
        gap: 10px;
    }
    
    .review-date {
        align-self: flex-start;
    }
}

@media (max-width: 780px) {
    .question-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      border: 1px solid #ddd;
      margin-bottom: 10px;
      border-radius: 4px;
    }
  
    .question-controls {
      display: flex;
      gap: 5px;
      margin-bottom: 10px;
      width: 100%;
      justify-content: space-between;
    }
  
    .question-controls button {
      flex: 1;
    }
  
    .question-item select,
    .question-item input[type="text"] {
      width: 95%;
      margin-bottom: 10px;
    }
  
    .required-checkbox {
      display: flex;
      align-items: center;
    }
  }