/* .pushb {
    font-size: 14px;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    white-space: nowrap;
    margin: 5px;
  } */

  .push-subscription-container {
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .enable-push-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin: 10px 0;
  }
  
  .enable-push-button:hover {
    background-color: #0056b3;
  }
  
  .notification-popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 15px;
  }
  
  .popup-buttons {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  
  .pushb {
    padding: 8px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .pushb.allow {
    background-color: #28a745;
    color: white;
  }
  
  .pushb.block {
    background-color: #dc3545;
    color: white;
  }
  
  .error-message {
    color: #dc3545;
    margin: 10px 0;
  }
  
  .success-message {
    color: #28a745;
    margin: 10px 0;
  }
  
  .subscription-status {
    margin: 10px 0;
  }
  
  .unsubscribe-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }