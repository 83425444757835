.vendor-dashboard {
    /* max-width: 1200px;
    margin: 0 auto;
    padding: 20px; */
    display: flex;
    min-height: 100vh;
    background-color: #f0f4f8;
  }
  
  h1, h2 {
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  /* .logout-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    float: right;
  } */
  
  .create-program-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 30px;
  }
  
  .create-program-form input,
  .create-program-form button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .create-program-form button {
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
    width: 300px;
    margin: 0 auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }
  
  th, td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #3498db;
    color: white;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  /* .toggle-active-btn {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  } */
  
  .redeem-input {
    width: 100px;
    margin-right: 10px;
  }
  
  .redeem-btn {
    background-color: #f39c12;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }

  .communication-section {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .email-campaign-section,
  .push-notification-section {
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .create-campaign-btn {
    background-color: #4CAF50;
    width: 300px;
    margin: 10px 0;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .create-campaign-btn:hover {
    background-color: #45a049;
  }
  
  .push-notification-section form {
    display: flex;
    flex-direction: column;
  }
  
  .push-notification-section input,
  .push-notification-section textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .push-notification-section button {
    background-color: #008CBA;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    width: 300px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .push-notification-section button:hover {
    background-color: #007B9A;
  }

  .request-increase-btn {
    background-color: #ff9800;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .send-result {
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
  }
  
  .send-result.success {
    background-color: #dff0d8;
    color: #3c763d;
  }
  
  .send-result.error {
    background-color: #f2dede;
    color: #a94442;
  }
  
  .create-campaign-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  
  .sidebar {
    width: 200px;
    background-color: #2c3e50;
    padding: 20px 0;
  }
  
  .sidebar button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 10px;
    text-align: left;
    background: none;
    border: none;
    color: #ecf0f1;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .sidebar button:hover,
  .sidebar button.active {
    background-color: #34495e;
  }
  
  .tab-content {
    flex-grow: 1;
    padding: 20px;
  }
  
  .tab-content h1 {
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .logout-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .logout-btn:hover {
    background-color: #c0392b;
  }


  .customer-search {
    margin-bottom: 20px;
    max-width: 70%;
  }
  
  .customer-search input {
    margin-right: 10px;
    padding: 5px;
  }
  
 .search-results {
  margin-top: 10px;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
}
  
  .customer-result {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .customer-result:hover {
    background-color: #f5f5f5;
  }
  
  .customer-profile {
    border: 1px solid #ddd;
    padding: 20px;
    margin-top: 20px;
  }
  
  .customer-profile button {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  /* .toggle-qr-btn, .generate-qr-btn {
    margin-left: 10px;
  } */
  
  /* .qr-code-image {
    max-width: 100px;
    max-height: 100px;
  } */

  .loyalty-programs-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  
  .loyalty-programs-table th,
  .loyalty-programs-table td {
    vertical-align: top;
    padding: 8px;
    text-align: left;
  }
  
  .loyalty-programs-table tr {
    background-color: #f8f9fa;
  }
  
  .button-group {
    display: flex;
    gap: 10px;
  }
  
  .btn {
    padding: 3px 6px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .btn-deactivate {
    background-color: #dc3545;
    color: white;
  }
  
  .btn-deactivate:hover {
    background-color: #c82333;
  }
  
  .btn-activate {
    background-color: #28a745;
    color: white;
  }
  
  .btn-activate:hover {
    background-color: #218838;
  }
  
  .btn-qr-toggle {
    background-color: #17a2b8;
    color: white;
  }
  
  .btn-qr-toggle:hover {
    background-color: #138496;
  }
  
  .btn-generate-qr {
    background-color: #ffc107;
    color: #212529;
  }
  
  .btn-generate-qr:hover {
    background-color: #e0a800;
  }
  
  .qr-code-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 150px;
    height: 150px;
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    padding: 10px;
  }
  
  .qr-code-image {
    max-width: 100%;
    max-height: 100%;
  }

  .loyalty-programs-table .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .loyalty-programs-table .btn {
    padding: 4px 8px;
    font-size: 12px;
  }

  .btn-sm {
    padding: 3px 6px;
    font-size: 11px;
  }

  .loyalty-programs-table input[type="text"],
.loyalty-programs-table input[type="number"] {
  width: 100%;
  padding: 4px;
  margin-bottom: 4px;
  font-size: 14px;
}

.loyalty-programs-table .edit-mode {
  display: flex;
  flex-direction: column;
}

.loyalty-programs-table .edit-buttons {
  display: flex;
  gap: 4px;
  margin-top: 4px;
}


.security-btn {
  margin: 5px;
  padding: 8px 16px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
}

.profile-section {
  padding: 20px;
}


.events-management {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-width: 1200px;
  margin: 0 auto;
}

.events-management .tab-buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
  padding: 0 10px;
}

.events-management .tab-button {
  padding: 12px 24px;
  border: none;
  background: #f0f0f0;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.events-management .tab-button:hover {
  background: #e0e0e0;
}

.events-management .tab-button.active {
  background: #3498db;
  color: white;
}

.events-management .tab-button:not(.active) {
  background: #f39c12;
  color: white;
}

.events-management .section-item, 
.events-management .event-item {
  background: white;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: box-shadow 0.3s ease;
}

.events-management .section-item:hover,
.events-management .event-item:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.events-management .form-group {
  margin-bottom: 20px;
}

.events-management input[type="text"],
.events-management input[type="date"],
.events-management input[type="time"],
.events-management textarea {
  width: 95%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  margin-bottom: 15px;
}

.events-management input:focus,
.events-management textarea:focus {
  border-color: #3498db;
  outline: none;
}

.events-management .file-preview {
  max-width: 300px;
  margin: 15px 0;
}

.events-management .file-preview img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.events-management .section-controls,
.events-management .event-controls {
  display: flex;
  gap: 15px;
  margin-top: 15px;
}

.events-management button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
  margin-top: 10px;
}

.events-management .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.events-management .order-controls {
  display: flex;
  gap: 8px;
}

.events-management .order-controls button {
  padding: 6px;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;
}

.events-management .order-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.events-management .add-option {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #dee2e6;
}

.events-management .loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.2em;
  color: #333;
}

.events-management .event-datetime {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  margin: 10px 0;
}

.option-item {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.option-controls {
  display: flex;
  gap: 10px;
}

.option-edit-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option-edit-form input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.option-edit-controls {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.option-edit-controls button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.option-edit-controls button:first-child {
  background-color: #3498db;
  color: white;
}

.option-edit-controls button:last-child {
  background-color: #e74c3c;
  color: white;
}

.option-action-container,
.event-action-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.action-message {
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  animation: fadeIn 0.3s ease-in;
}

.action-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.action-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.spinner {
  display: inline-block;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.option-edit-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option-edit-controls {
  display: flex;
  gap: 10px;
  align-items: center;
}

.option-edit-controls button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.option-action-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.action-message {
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
}

.option-edit-form input:disabled,
.option-edit-form button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.section-form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 10;
}

.sidebar-toggle {
  display: none !important; 
}


/* Mobile-specific styles */
@media (max-width: 768px) {
  .vendor-dashboard {
    flex-direction: column; /* Stack sidebar and content */
    padding: 0;
  }

  .vendor-dashboard .sidebar-toggle {
    display: block !important;
  }

  /* .sidebar {
    width: 100%;
    padding: 10px 0;
    position: sticky;
    top: 0;
    z-index: 100;
  } */

    /* Collapsible sidebar */
    .sidebar-toggle {
      display: block;
      width: 100%;
      padding: 15px;
      background: #34495e;
      color: white;
      border: none;
      text-align: left;
      position: relative;
      z-index: 2;
    }
  
    .sidebar {
      position: relative;
      height: auto;
      overflow: hidden;
      transition: height 0.3s ease;
      padding: 0px 0px 20px 0px;
    }
  
    .sidebar.collapsed {
      height: 7px; /* Height of toggle button */
    }
  
    .sidebar.collapsed button:not(.sidebar-toggle) {
      display: none; /* Hide all buttons except toggle when collapsed */
    }
  
    .sidebar button:not(.sidebar-toggle) {
      /* Styles for menu items */
      width: 100%;
      padding: 12px 15px;
      text-align: left;
      background: #2c3e50;
      border: none;
      color: white;
      margin: 0;
      border-bottom: 1px solid #34495e;
    }
  
    .sidebar-toggle::after {
      content: '▼';
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  
    .sidebar.collapsed .sidebar-toggle::after {
      content: '▲';
    }

  .tab-content {
    padding: 10px;
    margin-top: 10px;
  }

  /* Adjust table for mobile */
  .loyalty-programs-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  /* Make buttons stack on mobile */
  .button-group {
    flex-direction: column;
    gap: 5px;
  }

  /* Adjust communication section layout */
  .communication-section {
    flex-direction: column;
    padding: 15px;
  }

  .email-campaign-section,
  .push-notification-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .create-campaign-btn {
    width:250px;
  }

  .push-notification-section button{
    width:250px
  }

  .email-modal{
    width:90%;
  }

  /* Adjust form inputs */
  input, textarea, select {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  /* Adjust logout button position */
  .logout-btn {
    padding: 8px 15px;
    font-size: 14px;
    width: auto;
    position: absolute;
    top: 10px;
    right: 10px;
  }


  /* Adjust QR code container */
  .qr-code-container {
    width: 100%;
    height: auto;
  }

  /* Adjust create program form */
  .create-program-form button {
    width: 100%;
  }

  /* Make headings more compact */
  h1 {
    font-size: 20px;
    margin: 10px 0;
  }

  h2 {
    font-size: 18px;
    margin: 8px 0;
  }

  /* Adjust events management section */
  .events-management {
    padding: 15px;
  }

  .events-management .tab-buttons {
    flex-wrap: nowrap;
    gap: 10px;
  }

  .events-management input[type="text"],
  .events-management input[type="date"],
  .events-management input[type="time"],
  .events-management textarea {
    width: 100%;
    padding: 8px;
  }

  .events-management .tab-button {
    padding: 10px !important;
}

  /* Adjust customer search section */
  .customer-search {
    max-width: 100%;
  }

  input[type="text"], input[type="number"], input[type="url"], textarea {
    width: 85%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
    margin-bottom: 5px;
}

.save-settings-btn {
  width: 57% !important;
}

.option-header {
  display: block;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

}

/* Additional responsive fixes */
@media (max-width: 480px) {
  .sidebar button {
    font-size: 13px;
    padding: 6px 12px;
  }

  .button-group .btn {
    width: 100%;
    margin: 2px 0;
  }

  .events-management .section-controls,
  .events-management .event-controls {
    flex-direction: column;
    gap: 8px;
  }
}

.save-btn-event{
  background-color: #4CAF50 !important; 
}