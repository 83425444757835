.specials-and-menus {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-width: 1200px;
  margin: 0 auto;
}

.specials-section, .menus-section {
  margin-bottom: 40px;
}

h2 {
  color: #333;
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-size: 24px;
}

.form-grid {
  display: grid;
  gap: 20px;
  margin-bottom: 25px;
}

input[type="text"],
input[type="number"],
input[type="url"],
textarea {
  width: 95%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  margin-bottom: 5px;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="url"]:focus,
textarea:focus {
  border-color: #3498db;
  outline: none;
}

textarea {
  height: 120px;
  resize: vertical;
}

.file-input-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.file-input-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.file-input-wrapper label {
  background-color: #f0f0f0;
  padding: 12px 20px;
  border-radius: 4px;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.file-input-wrapper:hover label {
  background-color: #e0e0e0;
}

.submit-btn, .load-more-btn, .edit-btn, .delete-btn {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin: 20px 0;
  width:300px;
}

.submit-btn:hover, .load-more-btn:hover {
  background-color: #2980b9;
}

.specials-list, .menus-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px;
  padding-top: 10px;
}

.special-item, .menu-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  transition: box-shadow 0.3s ease;
}

.special-item:hover, .menu-item:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.special-item h3, .menu-item h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #2c3e50;
  font-size: 20px;
}

.special-item img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 15px;
}

.price {
  font-weight: bold;
  color: #e53935;
  font-size: 18px;
  margin-bottom: 10px;
}

.weblink, .pdf-link {
  display: inline-block;
  margin-top: 15px;
  color: #1976D2;
  text-decoration: none;
  font-weight: bold;
}

.weblink:hover, .pdf-link:hover {
  text-decoration: underline;
}

.button-group {
  display: flex;
  gap: 10px; /* This adds space between the buttons */
  margin-top: 10px; /* This adds space above the button group */
}

.edit-btn {
  background-color: #FFC107;
  color: #000;
}

.edit-btn:hover {
  background-color: #FFA000;
}

.delete-btn {
  background-color: #F44336;
}

.delete-btn:hover {
  background-color: #D32F2F;
}

.load-more-btn {
  display: block;
  margin: 30px auto 0;
  width: 200px;
}

.error-message, .success-message {
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 25px;
  font-weight: bold;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.menu-input-group {
  margin-bottom: 20px;
}

.menu-input-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.menu-input-help {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .specials-and-menus {
      padding: 20px;
  }

  .form-grid {
      grid-template-columns: 1fr;
  }

  .specials-list, .menus-list {
      grid-template-columns: 1fr;
  }
}

.menu-thumbnail {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.2em;
  color: #333;
}

.action-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.action-message {
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  animation: fadeIn 0.3s ease-in;
}

.action-message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.action-message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.spinner {
  display: inline-block;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.specials-and-menus h2 {
  scroll-margin-top: 20px;
}

/* Update existing button styles to handle disabled state */
.submit-btn:disabled,
.load-more-btn:disabled,
.edit-btn:disabled,
.delete-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: #ccc;
}

.special-item .button-group {
  position: relative;
}

.special-item .action-message {
  position: absolute;
  right: 0;
  bottom: -30px;
  white-space: nowrap;
}