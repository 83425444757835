.dashboard-container {
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .dashboard-content {
    padding: 24px;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  /* Header Section */
  .header-section {
    background: linear-gradient(to right, #3b82f6, #2563eb);
    border-radius: 8px;
    padding: 24px;
    color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  .header-title {
    font-size: 1.875rem;
    font-weight: 700;
    margin: 0;
    color: white !important;
  }
  
  .plan-badge {
    background: white;
    color: #2563eb;
    padding: 4px 16px;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }


  .subscription-status-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.plan-badge.free {
  background-color: #e5e7eb;
  color: #374151;
}

.plan-badge.premium {
  background-color: #fde68a;
  color: #92400e;
}

.free-plan-details, .premium-plan-details {
  font-size: 14px;
  color: #6b7280;
}

.customers-remaining {
  margin-bottom: 4px;
  color: white
}

.upgrade-button, .update-payment-button {
  background-color: #e96529;
  color: white;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.2s;
}

.upgrade-button:hover, .update-payment-button:hover {
  background-color: #f34d00;
}

.payment-alert {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #dc2626;
  font-size: 14px;
}

.alert-icon {
  width: 16px;
  height: 16px;
}

  
  /* Stats Card */
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
  }
  
  .stats-card {
    background: white;
    border-radius: 8px;
    padding: 24px;
    transition: all 0.3s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .stats-card:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .stats-card-content {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .icon-container {
    background-color: #eff6ff;
    padding: 12px;
    border-radius: 9999px;
  }
  
  .stats-icon {
    width: 32px;
    height: 32px;
    color: #2563eb;
  }
  
  .stats-label {
    color: #4b5563;
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0;
  }
  
  .stats-value {
    color: #2563eb;
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
  }
  
  /* QR Code Section */
  .qr-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
  }
  
  .qr-card {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .qr-card:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .qr-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: #1f2937;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
  }
  
  .qr-title-icon {
    width: 20px;
    height: 20px;
    color: #2563eb;
  }
  
  .qr-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  
  .qr-wrapper {
    position: relative;
    background: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .qr-code {
    display: block;
  }
  
  .download-button {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 8px;
    background: white;
    border: none;
    border-radius: 9999px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
  }
  
  .qr-wrapper:hover .download-button {
    opacity: 1;
  }
  
  .download-icon {
    width: 16px;
    height: 16px;
    color: #4b5563;
  }
  
  .qr-description {
    color: #4b5563;
    font-size: 0.875rem;
    text-align: center;
    margin: 0;
  }
  
  /* URL Container */
  .url-container {
    width: 100%;
  }
  
  .url-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #f9fafb;
    padding: 12px;
    border-radius: 8px;
  }
  
  .url-input {
    width: 100%;
    background: transparent;
    border: none;
    font-size: 0.875rem;
    color: #1f2937;
  }
  
  .url-input:focus {
    outline: none;
  }
  
  .url-action-button {
    color: #2563eb;
    padding: 8px;
    border: none;
    background: transparent;
    border-radius: 9999px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .url-action-button:hover {
    background: #eff6ff;
  }
  
  .url-action-icon {
    width: 16px;
    height: 16px;
  }
  
  .copy-message {
    color: #059669;
    font-size: 0.875rem;
    text-align: center;
    margin: 8px 0 0;
  }
  
  /* Walkthrough Section */
  .walkthrough-card {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .walkthrough-card:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
  
  .walkthrough-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
  }
  
  .walkthrough-title-icon {
    width: 24px;
    height: 24px;
    color: #2563eb;
  }
  
  .progress-bar-container {
    width: 100%;
    height: 8px;
    background: #f3f4f6;
    border-radius: 9999px;
    margin-bottom: 24px;
  }
  
  .progress-bar {
    height: 100%;
    background: #2563eb;
    border-radius: 9999px;
    transition: width 0.5s ease;
  }
  
  .step-counter {
    color: #6b7280;
    font-size: 0.875rem;
    margin-bottom: 16px;
  }
  
  .step-content {
    min-height: 300px;
  }
  
  .step-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav-button {
    padding: 12px;
    border: none;
    background: transparent;
    border-radius: 9999px;
    cursor: pointer;
    color: #2563eb;
    transition: background-color 0.3s ease;
  }
  
  .nav-button:hover:not(.nav-button-disabled) {
    background: #eff6ff;
  }
  
  .nav-button-disabled {
    color: #d1d5db;
    cursor: not-allowed;
  }
  
  .nav-icon {
    width: 24px;
    height: 24px;
  }
  
  .step-details {
    flex: 1;
    margin: 0 auto;
    text-align: center;

  }
  
  .step-icon-wrapper {
    width: 48px;
    height: 48px;
    background: #2563eb;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
  }
  
  .step-icon {
    width: 24px;
    height: 24px;
    color: white;
  }
  
  .step-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
    margin: 0 0 12px;
  }
  
  .step-description {
    color: #4b5563;
    margin: 0 0 16px;
  }
  
  .step-highlight {
    color: #2563eb;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Responsive Adjustments */
  @media (max-width: 640px) {
    .dashboard-content {
      padding: 16px;
    }
  
    .header-content {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  
    .header-title {
      font-size: 1.5rem;
      color: white !important;
    }
  
    .stats-value {
      font-size: 3rem;
    }
  }

  .dashboard-container.loading {
    min-height: 400px;
    position: relative;
  }
  
  .dashboard-container.loading .dashboard-content {
    opacity: 0.7;
    pointer-events: none;
    user-select: none;
  }
  
  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-color: #f0f0f0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
  
  .header-section.skeleton,
  .stats-card.skeleton,
  .qr-card.skeleton,
  .walkthrough-card.skeleton {
    border-radius: 5px;
  }
  
  .header-section.skeleton {
    height: 150px;
    margin-bottom: 20px;
  }
  
  .stats-card.skeleton {
    height: 100px;
  }
  
  .qr-card.skeleton {
    height: 300px;
  }
  
  .walkthrough-card.skeleton {
    height: 400px;
    margin-top: 20px;
  }

