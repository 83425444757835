.review-card {
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
    max-width: 600px;
    margin: 20px auto;
    padding: 24px;
    transition: all 0.3s ease;
}

.review-card-header {
    text-align: center;
    margin-bottom: 32px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.vendor-logo-review {
    width: auto;
    height: 80px;
    margin-bottom: 16px;
    object-fit: contain;
}

.vendor-info-review {
    color: #666;
    font-size: 0.9rem;
    line-height: 1.5;
}

.review-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.reviewer-info-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 12px;
    margin-bottom: 24px;
}

.reviewer-info-section input {
    padding: 12px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.reviewer-info-section input:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.1);
}

.question-container-review {
    margin-bottom: 24px;
}

.question-container label {
    display: block;
    font-weight: 500;
    margin-bottom: 12px;
    color: #2c3e50;
}

.star-rating {
    display: flex;
    gap: 8px;
    margin: 12px 0;
}

.star {
    background: none;
    border: none;
    font-size: 40px;
    color: #c4bdbd;
    cursor: pointer;
    transition: color 0.2s ease;
}

.star:hover {
    transform: scale(1.1);
    background: none !important
}

.star.active {
    color: #f1c40f;
}

.textarea-review {
    width: 90%;
    min-height: 1rem;
    max-height: 4rem;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    resize: vertical;
    transition: border-color 0.3s ease;
}

.textarea-review:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.1);
}

.submit-button-review {
    background: #3498db;
    color: white;
    border: none;
    padding: 14px 28px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}

.submit-button-review:hover {
    background: #2980b9;
    transform: translateY(-1px);
}

.submit-button-review:disabled {
    background: #95a5a6;
    cursor: not-allowed;
    transform: none;
}

.review-card-success {
    text-align: center;
    padding: 40px 24px;
}

.review-card-success h2 {
    color: #27ae60;
    margin-bottom: 16px;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
    .review-card {
        margin: 3px;
        padding: 20px;
        border-radius: 12px;
    }

    .star {
        font-size: 35px;
    }

    .submit-button-review {
        padding: 14px 28px;
    }

    .textarea-review {
        width: 100%;
    }
}


.review-card-success {
    text-align: center;
    padding: 40px 24px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
    max-width: 600px;
    margin: 20px auto;
}

.review-card-success h2 {
    color: #27ae60;
    margin-bottom: 16px;
    font-size: 2rem;
}

.review-card-success .check-icon {
    color: #27ae60;
    font-size: 64px;
    margin-bottom: 24px;
}

.review-card-success p {
    color: #666;
    margin-bottom: 16px;
    line-height: 1.6;
}

.return-link {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 24px;
    background: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    transition: background 0.3s ease;
}

.return-link:hover {
    background: #2980b9;
}


.required-field {
    color: #e74c3c;
    margin-left: 4px;
}

.loading-overlay-review {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-spinner-review {
    width: 50px;
    height: 50px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.review-card-title {
    text-align: center;
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 24px;
    font-weight: 600;
}