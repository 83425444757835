.pin-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .pin-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 300px;
  }
  
  .pin-modal-content h3 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .pin-modal-content form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .pin-modal-content input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    text-align: center;
    letter-spacing: 3px;
  }
  
  .pin-modal-buttons {
    display: flex;
    gap: 10px;
    justify-content: space-between; /* Changed from center to space-between */
    margin-top: 20px;
  }


.submit-btn, .cancel-btn {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  flex: 1; 
  max-width: 170px; 
  margin: 0 auto;
}
  
  .submit-btn {
    background-color: #4CAF50;
    color: white;
  }
  
  .submit-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .cancel-btn {
    background-color: #f44336;
    color: white;
  }
  
  .error-message {
    color: #f44336;
    text-align: center;
    margin: 0;
    font-size: 14px;
  }