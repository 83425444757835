.auth-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .auth-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .auth-container form {
    display: flex;
    flex-direction: column;
  }
  
  .auth-container input,
  .auth-container select {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .auth-container button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-container button:hover {
    background-color: #2980b9;
  }

  .forgot-password {
    background-color: transparent !important;
  color: #007bff;
  margin-top: 10px;
  text-align: center;
  }

  .forgot-password-link{
    background: none !important;
  border: none;
  color: #007bff !important;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  text-decoration: none
  }

  .close-btn{
    background:none !important;
    border: none;
    font-size: 24px;
    color: #666 !important;
    cursor: pointer;
    padding: 0;
    line-height: 1;
  }

  .password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-40%);
    background: none !important;
    border: none;
    cursor: pointer;
    padding: 5px;
    color: #007bff;
    font-size: 14px;
    margin: 0;
    box-shadow: none;
    min-width: 40px;
    text-align: right;
  }
  
  .password-toggle:hover {
    background: none !important;
    color: #0056b3;
    text-decoration: underline;
  }
  
  .password-input-group input {
    padding-right: 55px;
    width: 100% !important;
    box-sizing: border-box;
  }

  .auth-container input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 35px 10px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
   }

   .auth-container button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .auth-container input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  
  /* Optional: Add a loading state animation */
  .auth-container button:disabled {
    position: relative;
    overflow: hidden;
  }
  
  .auth-container button:disabled::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    animation: loading 1.5s infinite;
  }
  
  @keyframes loading {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
   