.inbox {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .close-inbox {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .close-inbox:hover {
    background-color: #d32f2f;
  }
  
  .notification-list {
    list-style-type: none;
    padding: 0;
  }
  
  .notification {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 10px;
  }
  
  .notification.unread {
    background-color: #e6f3ff;
  }
  
  .notification h3 {
    margin-top: 0;
    color: #333;
  }
  
  .notification-date {
    font-size: 0.8em;
    color: #777;
  }
  
  .mark-read-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
  }
  
  .mark-read-btn:hover {
    background-color: #45a049;
  }