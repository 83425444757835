.booking-section {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.booking-section h2 {
    margin-bottom: 20px;
}

.booking-section form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.booking-section label {
    margin-bottom: 5px;
}

.booking-section input,
.booking-section textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; 
    box-sizing: border-box;
    resize: vertical;
}

.booking-section button {
    padding: 10px 15px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 200px;
    margin:auto
}

.booking-section button:disabled {
    background-color: #ccc;
}

.booking-section table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.booking-section th,
.booking-section td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.success-message {
    color: #2ecc71;
    margin-top: 10px;
}

.error-message {
    color: #e74c3c;
    margin-top: 10px;
}

@media (max-width: 600px) {
    .booking-section {
        padding: 10px;
    }

    .booking-section table, 
    .booking-section thead, 
    .booking-section tbody, 
    .booking-section th, 
    .booking-section td, 
    .booking-section tr { 
        display: block; 
    }
    
    .booking-section thead tr { 
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    
    .booking-section tr { 
        margin: 0 0 1rem 0; 
        border: 1px solid #ccc;
    }
        
    .booking-section tr:nth-child(odd) { 
        background: #f9f9f9; 
    }
    
    .booking-section td { 
        border: none;
        position: relative;
        padding-left: 53%; 
        padding-top: 6px;
        padding-bottom: 6px;
    }
    
    .booking-section td:before { 
        content: attr(data-label);
        position: absolute;
        top: 6px;
        left: 4px;
        width: 45%; 
        padding-right: 10px; 
        white-space: nowrap;
        font-weight: bold;
    }
}

.booking-section input[type="date"],
.booking-section input[type="time"] {
  width: 100%;
  padding: 12px 40px 12px 12px; /* Increased padding and space for icon */
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  position: relative;
}

.booking-section input[type="date"]::-webkit-calendar-picker-indicator,
.booking-section input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  padding: 4px;
  background-color: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
}

/* Small screens (phones) */
@media screen and (max-width: 480px) {
  .booking-section input[type="date"],
  .booking-section input[type="time"] {
    font-size: 14px;
    padding: 10px 36px 10px 10px;
  }

  .booking-section input[type="date"]::-webkit-calendar-picker-indicator,
  .booking-section input[type="time"]::-webkit-calendar-picker-indicator {
    width: 18px;
    height: 18px;
  }
}

/* Medium screens (tablets) */
@media screen and (min-width: 481px) and (max-width: 768px) {
  .booking-section input[type="date"],
  .booking-section input[type="time"] {
    font-size: 15px;
    padding: 11px 38px 11px 11px;
  }

  .booking-section input[type="date"]::-webkit-calendar-picker-indicator,
  .booking-section input[type="time"]::-webkit-calendar-picker-indicator {
    width: 19px;
    height: 19px;
  }
}

/* Large screens (desktops) */
@media screen and (min-width: 769px) {
  .booking-section input[type="date"],
  .booking-section input[type="time"] {
    font-size: 16px;
    padding: 12px 40px 12px 12px;
  }

  .booking-section input[type="date"]::-webkit-calendar-picker-indicator,
  .booking-section input[type="time"]::-webkit-calendar-picker-indicator {
    width: 20px;
    height: 20px;
  }
}

@media screen and (min-width: 768px) {
    .booking-section {
        max-width: 600px; /* Revert to original max-width for larger screens */
    }
}

.update-email-btn {
    margin-bottom: 20px; /* Adds space below the "Update Email Settings" button */
  }
  
  .pagination-container {
    margin-top: 20px; /* Adds space above the pagination buttons */
    display: flex;
    justify-content: center;
    gap: 10px; /* Adds space between the "Previous" and "Next" buttons */
  }
  
  .booking-management form {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .btn {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .btn-primary {
    background-color: #3498db;
    color: white;
  }
  
  .btn-primary:hover:not(:disabled) {
    background-color: #2980b9;
  }
  
  .btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
  }
  
  .pagination button {
    padding: 8px 12px;
  }
  
  .pagination span {
    font-weight: bold;
  }

  .current-day-booking {
    background-color: #d5ecdb !important;
}

.upcoming-booking {
    background-color: #f8f5ec !important;
}

.booking-section table tr {
  transition: background-color 0.3s ease;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
