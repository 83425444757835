.security-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
}

.security-modal {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 320px; /* Reduced from 500px */
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 90vh;
    overflow-y: auto;
    margin: 0 15px; /* Add margin to prevent touching edges */
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 24px;
    color: #666;
    cursor: pointer;
    padding: 0;
    line-height: 1;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
  }
  
  .form-group input {
    width: calc(100% - 24px); /* Account for padding and border */
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box; /* Important - this makes padding included in width */
  }
  
  .form-group input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
  }
  
  .password-requirements {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  .password-requirements h4 {
    margin: 0 0 10px 0;
    color: #666;
    font-size: 0.9rem;
  }
  
  .password-requirements ul {
    margin: 0;
    padding-left: 20px;
    color: #666;
    font-size: 0.9rem;
  }
  
  .password-requirements li {
    margin: 5px 0;
  }
  
  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 0.9rem;
  }
  
  .success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 0.9rem;
  }
  
  .form-actions {
    display: flex;
    gap: 10px;
    margin-top: 25px;
  }
  
  .form-actions button {
    flex: 1;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    min-width: 120px; 
  }
  
  .form-actions button[type="submit"] {
    background-color: #007bff;
    color: white;
  }
  
  .form-actions button[type="submit"]:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .form-actions button.cancel-btn {
    background-color: #6c757d;
    color: white;
    gap: 10px;
  }
  
  .form-actions button.cancel-btn:hover:not(:disabled) {
    background-color: #5a6268;
  }
  
  .form-actions button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Loading spinner */
  .loading-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

  .modal-actions button {
    font-size: 14px;
    min-width: 120px;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    white-space: nowrap;
    margin: 5px;
    
  }

/* Mobile specific adjustments */
@media (max-width: 480px) {
    .security-modal {
      width: 85%;
      padding: 15px;
      margin: 10px;
    }
  
    .modal-header h2 {
      font-size: 18px; /* Smaller font size on mobile */
    }
  
    .form-group label {
      font-size: 14px;
      margin-bottom: 5px;
    }
  
    .form-group input {
      font-size: 16px; /* Better for mobile input */
      padding: 8px 10px;
    }
  
    .modal-actions {
      flex-direction: column;
      gap: 8px;
    }
  
    .modal-actions button {
      font-size: 14px;
      min-width: 120px;
      padding: 12px 20px;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.3s ease;
      text-align: center;
      white-space: nowrap;
      margin: 5px;
      
    }
  
    .password-requirements {
      padding: 10px;
      font-size: 12px;
    }
  
    .password-requirements h4 {
      font-size: 13px;
    }
  
    .password-requirements ul {
      padding-left: 15px;
    }
  
    .password-requirements li {
      margin: 3px 0;
    }
  }
  
  /* Fix for iOS safari viewport height issue */
  @supports (-webkit-touch-callout: none) {
    .security-modal-overlay {
      height: -webkit-fill-available;
    }
  }

  .vendor-context {
    color: #666;
    font-size: 0.9em;
    margin-top: -10px;
    margin-bottom: 15px;
    text-align: center;
  }

  .standalone-form {
    max-width: 500px;
    margin: 40px auto;
    padding: 20px;
  }

  .back-link {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .back-link a {
    color: #3498db;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .back-link a:hover {
    text-decoration: underline;
  }
  
  .error-action {
    margin-top: 8px;
    font-size: 0.9em;
  }
  
  .error-action a {
    color: #3498db;
    text-decoration: none;
  }
  
  .error-action a:hover {
    text-decoration: underline;
  }
  
  .vendor-context {
    color: #666;
    font-size: 0.9em;
    margin: 5px 0;
    text-transform: capitalize;
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    color: #666;
  }
  
  .loading:before {
    content: '';
    width: 20px;
    height: 20px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }