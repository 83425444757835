.invoices-container {
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .invoices-container h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .invoices-table-container {
    overflow-x: auto;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .invoices-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .invoices-table th {
    background-color: #f8f9fa;
    padding: 12px;
    text-align: left;
    font-weight: 600;
    color: #333;
    border-bottom: 2px solid #e9ecef;
  }
  
  .invoices-table td {
    padding: 12px;
    border-bottom: 1px solid #e9ecef;
    color: #444;
  }
  
  .invoices-table tr:hover {
    background-color: #f8f9fa;
  }
  
  .status-badge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .status-badge.paid {
    background-color: #d1fae5;
    color: #065f46;
  }
  
  .status-badge.pending {
    background-color: #fef3c7;
    color: #92400e;
  }
  
  .status-badge.failed {
    background-color: #fee2e2;
    color: #991b1b;
  }
  
  .action-buttons-sub {
    display: flex;
    gap: 8px;
  }
  
  .action-buttons-sub button {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    color: #4b5563;
    transition: color 0.2s;
  }
  
  .action-buttons-sub button:hover {
    color: #1f2937;
  }
  
  .action-buttons-sub .icon {
    width: 20px;
    height: 20px;
  }
  
  .expanded-row {
    background-color: #f8f9fa;
  }
  
  .invoice-details {
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 24px;
  }
  
  .details-section-sub h4 {
    font-weight: 600;
    color: #333;
    margin-bottom: 8px;
  }
  
  .details-section-sub p {
    margin: 4px 0;
    color: #4b5563;
  }
  
  .loading-spinner-sub {
    text-align: center;
    padding: 40px;
    color: #666;
  }
  
  .error-message-sub {
    text-align: center;
    padding: 20px;
    color: #dc2626;
    background-color: #fee2e2;
    border-radius: 8px;
    margin: 20px 0;
  }
  
  .no-invoices {
    text-align: center;
    padding: 40px;
    color: #666;
    font-style: italic;
  }

/* sidebar style tests */
  .dashboard-layout {
    display: flex;
    min-height: 100vh;
    background-color: #f8f9fa;
  }
  
  .sidebar-t {
    width: 60px;
    background-color: #ffffff;
    border-right: 1px solid #e5e7eb;
    position: fixed;
    height: 100vh;
    z-index: 10;
  }
  
  .sidebar-content-t {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
  
  .sidebar-item-t {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    color: #1f2020;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.2s ease;
  }
  
  .sidebar-item-t:hover {
    background-color: #f3f4f6;
    color: #1f2937;
  }
  
  .sidebar-item-t.active {
    background-color: #e5e7eb;
    color: #1f2937;
  }
  
  .invoices-container {
    flex: 1;
    padding: 20px;
    margin-left: 60px; /* Match sidebar width */
    width: calc(100% - 60px);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .sidebar-t {
      width: 50px;
    }
  
    .invoices-container {
      margin-left: 50px;
      width: calc(100% - 50px);
    }
  
    .sidebar-item-t {
      width: 40px;
      height: 40px;
    }
  }

/* sidebar style tests */