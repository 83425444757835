.vendor-subscriptions {
    padding: 20px;
}

.vendors-list {
    display: grid;
    gap: 20px;
    margin-top: 20px;
}

.vendor-item {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.subscription-control {
    margin-top: 10px;
}

.manual-subscription {
    display: flex;
    align-items: center;
    gap: 15px;
}

.manual-subscription input[type="text"] {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex-grow: 1;
}

.subscription-status {
    margin-top: 10px;
    font-size: 0.9em;
    color: #666;
}