.register-pwa {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.register-pwa h2 {
  text-align: center;
  color: #333;
}

.register-pwa form {
  display: flex;
  flex-direction: column;
}

.register-pwa input, .register-pwa label {
  margin-bottom: 15px;
}

.register-pwa input[type="text"],
.register-pwa input[type="email"],
.register-pwa input[type="password"],
.register-pwa input[type="tel"] {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* .register-pwa button {
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
} */

.register-pwa button[type="submit"] {
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Make button full width */
  max-width: 200px; /* Limit maximum width */
  margin: 0 auto; /* Center the button */
  display: block; /* Make it a block element */
}

.register-pwa button:hover {
  background-color: #2980b9;
}

.register-pwa .error {
  color: red;
  margin-bottom: 15px;
}

.register-pwa .form-group {
  margin-bottom: 15px;
  position: relative;
}

.register-pwa .field-error {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: 4px;
  padding: 4px 0;
}

.register-pwa .error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.register-pwa .password-requirements {
  font-size: 0.8rem;
  color: #6c757d;
  margin-bottom: 4px;
}

.register-pwa .consent-label {
  display: flex;
  align-items: center; /* Center align instead of flex-start */
  gap: 8px;
  margin-bottom: 15px;
  font-size: 0.9rem;
  color: #666; /* Muted text color */
}

.register-pwa .consent-label input[type="checkbox"] {
  margin: 0; /* Remove default margins */
  width: 16px; /* Set specific width */
  height: 16px; /* Set specific height */
  accent-color: #3498db; /* Match button color */
}

.back-link {
  text-align: center;
  margin-top: 20px;
}

.back-link .back-button {
  background: none;
  border: none;
  color: #666;
  padding: 8px 15px;
  cursor: pointer;
  font-size: 14px;
}

.back-link .back-button:hover {
  color: #333;
}

.register-pwa button:disabled {
  background-color: #94c6e7;
  cursor: not-allowed;
}

.register-pwa button:disabled:hover {
  background-color: #94c6e7;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-50%);
  background: none !important;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #666 !important;
  font-size: 12px;
  margin: 0;
  box-shadow: none;
}

.password-toggle:hover {
  background: none !important;
  color: #333;
}