
.vendor-register-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.vendor-register-container::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background-image: url('https://images.unsplash.com/photo-1458819714733-e5ab3d536722?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
    background-position: center;
    opacity: 0.15;
    pointer-events: none;
}
  
.register-card {
    background: white;
    border-radius: 24px;
    padding: 40px;
    width: 100%;
    max-width: 480px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    margin: 0 auto;
}
  
  .register-card h1 {
    margin: 0 0 10px;
    font-size: 28px;
    color: #333;
    text-align: center;
  }
  
  .subtitle {
    color: #666;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .form-group {
    position: relative;
    margin-bottom: 24px;
  }
  
  .form-group input {
    width: 100%;
    padding: 16px;
    background: rgba(255, 255, 255, 0.9);
    border: 2px solid rgba(99, 102, 241, 0.2);
    border-radius: 12px;
    font-size: 16px;
    color: #1F2937;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .form-group input:focus {
    border-color: #6366F1;
    background: #fff;
    box-shadow: 0 0 0 4px rgba(99, 102, 241, 0.1);
  }
  
  .form-group input::placeholder {
    color: #9CA3AF;
    transition: color 0.3s ease;
  }
  
  .form-group input:focus::placeholder {
    color: #6B7280;
  }
  
  .form-group .input-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #9CA3AF;
    transition: color 0.3s ease;
  }
  
  .form-group input:focus + .input-icon {
    color: #6366F1;
  }
  
  
  .form-group input:required::after {
    content: '*';
    color: #e74c3c;
    position: absolute;
    top: 15px;
    right: 15px;
    border-radius: 10px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .form-group input:focus {
    border-color: #4a90e2;
    outline: none;
  }

  .register-button {
    width: 100%;
    padding: 15px;
    background: #4a90e2;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background: #357abd;
  }
  
  .register-button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  .error-message {
    background: #ffebee;
    color: #c62828;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* SetupWizard.css */
  .setup-wizard-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 20px;
  }
  
  .wizard-card {
    background: white;
    border-radius: 20px;
    padding: 40px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .progress-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    position: relative;
    background: transparent;
  }
  
  .progress-bar::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: #e1e1e1;
    z-index: 1;
  }
  
  .progress-step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
    border: 2px solid #e1e1e1;
    z-index: 2;
    transition: all 0.3s ease;
  }
  
  .progress-step.active {
    border-color: #4a90e2;
    background: #4a90e2;
  }
  
  .wizard-step {
    padding: 20px 0;
  }
  
  .wizard-step h2 {
    margin: 0 0 30px;
    text-align: center;
    color: #333;
  }
  
  .business-types-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .business-type-btn {
    padding: 15px;
    border: 2px solid #e1e1e1;
    border-radius: 10px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    color: black
}

.business-type-btn:hover {
    border-color: #4a90e2;
    background: rgba(74, 144, 226, 0.1);
    color: #4a90e2;
}

.business-type-btn.selected {
    border-color: #4a90e2;
    background: #4a90e2;
    color: white;
    box-shadow: 0 2px 4px rgba(74, 144, 226, 0.2);
}
  
  .logo-upload-container {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    border: 2px dashed #e1e1e1;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .logo-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .logo-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
  }
  
  .logo-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }


  .prev-button,
.next-button,
.finish-button {
  padding: 15px 30px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin:5px
}

.prev-button {
  background: #f5f5f5;
  color: #666;
}

.next-button,
.finish-button {
  background: #4a90e2;
  color: white;
}

.prev-button:hover {
  background: #e1e1e1;
}

.next-button:hover,
.finish-button:hover {
  background: #357abd;
}

.finish-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .wizard-card {
    padding: 20px;
  }

  .business-types-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  .wizard-buttons {
    flex-direction: column;
  }

  .prev-button,
  .next-button,
  .finish-button {
    width: 100%;
  }
}


@media (max-width: 768px) {
    .vendor-register-container {
        padding: 16px;
    }

    .register-card {
        padding: 24px;
        margin: 16px;
        width: calc(100% - 32px);
    }

    .vendor-register-container::before {
        width: 100%;
    }
}


.password-input-group {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #666;
  font-size: 12px;
}

.password-toggle:hover {
  color: #333;
}
