.vendor-public-page {
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
}

header {
  background-color: #ffffff;
  padding: 5px 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-title-container {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-right: 6px;
  max-height: 600px;
}

.vendor-logo {
  width: 9em;  /* Increased from 80px */
  height: auto;  /* Changed from max-height to maintain aspect ratio */
}

h1 {
  margin: 0;
  font-size: 20px;  /* Reduced from 24px */
  color: #333;
  font-weight: 600;  /* Make it slightly bolder */
  flex-grow: 1;  /* Allow title to take up remaining space */
  text-align: left;  /* Align text to the left */
}

@media (max-width: 480px) {
  .vendor-logo {
    width: 9em;  
  }
  
  h1 {
    font-size: 16px;  /* Even smaller on mobile */
  }
}

.cover-image-container {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Slightly taller for tablets */
@media (min-width: 500px) {
  .cover-image-container {
    height: auto;
  }
}

/* Medium height for desktop */
@media (min-width: 1024px) {
  .cover-image-container {
    height:auto;
    max-width: 1024px;
    margin: 0 auto;
  }
}

.vendor-info {
  background-color: #ffffff;
  padding: 15px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.vendor-info p {
  margin: 5px 0;
  color: #666;
}

.vendor-info i {
  margin-right: 10px;
  color: #3498db;
}

.tab-navigation {
  display: flex;
  justify-content: space-around;
  background-color: #ffffff;
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.tab-navigation button {
  background: none;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
  touch-action: manipulation; /* Disable double-tap to zoom */
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight on iOS */
  border-bottom: 2px solid transparent; /* Add transparent border by default */
  position: relative; /* For absolute positioning of active indicator */
}

.tab-navigation button.active {
  color: #1b1b1b;
  border-bottom: 2px solid #80c7f5;
}

.tab-navigation button:hover {
  color: #5fb8f3;
  background: none;
  border-bottom: 2px solid #80c7f5;
}

.tab-navigation button:hover:not(.active) {
  color: #5fb8f3;
  border-bottom-color: transparent; /* Ensure hover doesn't show border if not active */
}

.tab-navigation button:focus {
  outline: none;
}

@supports (-webkit-touch-callout: none) {
  .tab-navigation {
    padding: max(15px, env(safe-area-inset-top)) 0 15px; /* Account for notch */
  }
  
  .tab-navigation button {
    /* Prevent text selection */
    -webkit-user-select: none;
    user-select: none;
    
    /* Disable contextual menus */
    -webkit-touch-callout: none;
  }
}


.tab-content {
  padding: 20px;
  background-color: #ffffff;
  min-height: 300px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.program-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.program-card h3 {
  margin-top: 0;
  color: #3498db;
}

.view-card-link, .enroll-btn {
  display: inline-block;
  padding: 8px 15px;
  background-color: #3498db;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.view-card-link:hover, .enroll-btn:hover {
  background-color: #2980b9;
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366;
  color: white;
  padding: 5px;
  border-radius: 50%;
  text-decoration: none;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.whatsapp-text {
  display: none;
}

@media (min-width: 768px) {
  .whatsapp-button {
    border-radius: 25px;
    width: auto;
    height: auto;
    padding: 10px 20px;
  }

  .whatsapp-text {
    display: inline;
    margin-left: 8px;
  }
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
  color: #3498db;
}

@media (min-width: 768px) {
  .vendor-public-page {
    max-width: 768px;
    margin: 0 auto;
  }
}

.vendor-carousel {
  max-width: 500px;  /* Adjust this value as needed */
  margin: 0 auto 20px;
}

.carousel-image {
  width: 100%;
  height: auto;  /* Adjust this height as needed */
  object-fit: cover;
  object-position: center;
}

.react-multi-carousel-item {
  height: auto;  /* Should match the height of .carousel-image */
}

.react-multi-carousel-dot-list {
  bottom: 10px;
}

.react-multi-carousel-dot button {
  border-color: #fff;
}

.react-multi-carousel-dot--active button {
  background: #fff;
}

.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px) !important;
}

.specials-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.special-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px; /* Adjust this value as needed */
  width: 100%;
}

.special-item h3 {
  margin-bottom: 10px;
}

.special-item p {
  margin-bottom: 10px;
}

.special-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.special-image {
  max-width: 100%;
  width: 300px; /* Adjust this value as needed */
  height: auto;
  border-radius: 4px;
  display: block;
}

.special-link {
  margin-top: 10px;
  display: inline-block;
}

.menu-item {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-thumbnail {
  max-width: 200px; /* Adjust this value as needed */
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 4px;
}

.view-menu-btn {
  display: inline-block;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 4px;
  margin-top: 10px;
}

.view-menu-btn:hover {
  background-color: #2980b9;
}

.welcome-screen {
  text-align: center;
  /* padding: 20px; */
}

.welcome-screen h1 {
  margin-bottom: 20px;
}

.welcome-screen .cover-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.welcome-screen-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.welcome-screen-buttons button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.welcome-screen-buttons button:hover {
  background-color: #2980b9;
}

.install-app-btn {
  background-color: #2ecc71 !important;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.install-app-btn:hover {
  background-color: #27ae60 !important;
}

.login-btn {
  background-color: #3498db !important;
  border-radius: 4px;
}

.login-btn:hover {
  background-color: #2980b9 !important;
}


.profile-menu.active {
  display: block;
}

.profile-menu.hidden {
  display: none;
}

.profile-menu a, .profile-menu button {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
}

.profile-menu a:hover, .profile-menu button:hover {
  background-color: #f5f5f5;
}


.profile-menu {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  z-index: 1000;

  /* iOS-specific - safely ignored by other devices */
  padding-top: env(safe-area-inset-top); /* Only applies on iOS with notch */
  margin-top: max(20px, env(safe-area-inset-top)); /* Fallback for non-iOS */
  font-size: 16px; 
}

@supports (-webkit-touch-callout: none) {
  .profile-menu {
    /* iOS-only styles */
    padding-top: max(10px, env(safe-area-inset-top));
  }

  .profile-button {
    top: max(10px, env(safe-area-inset-top));
    padding-top: env(safe-area-inset-top);
  }
}

.profile-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.profile-button svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
  .profile-button {
    top: 5px;
    right: 5px;
    width: 36px;
    height: 36px;
  }

  .profile-button svg {
    width: 20px;
    height: 20px;
  }
}

.profile-button .fa-user {
  color: #333; /* Or any color you prefer */
  font-size: 24px; /* Adjust size as needed */
  transition: color 0.3s ease;
  border: solid;
  border-radius: 50%;
  padding: 5px
}

.profile-button:hover .fa-user {
  color: #80a0f8; /* Darker shade for hover effect */
}

.about-btn {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #f39c12;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.about-btn:hover {
  background-color: #e67e22;
}

.about-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.about-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.about-modal-content h3 {
  margin-top: 0;
}

.about-modal-content button {
  margin-top: 15px;
  padding: 5px 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.about-modal-content button:hover {
  background-color: #2980b9;
}

.customer-info {
  padding: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}

.customer-info p {
  margin: 5px 0;
  font-size: 16px;
}

.customer-info p:first-child {
  font-weight: bold;
}

/* .specials-subnav {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.specials-subnav button {
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.specials-subnav button.active {
  background-color: #3498db;
  color: white;
}

.specials-events-tab {
  padding: 20px;
} */

/* Specials & Events sub-navigation styling */
.specials-subnav {
  display: flex;
  justify-content: center;
  gap: 7px;
  padding: 12px;
  background-color: #ffffff;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.05); */
  margin: 0 auto 20px;
  max-width: 600px;
  /* position: sticky; */
  /* top: 90px; */
  z-index: 9;
  min-width: 100px;
  white-space: nowrap;
}

.specials-subnav button {
  padding: 8px 20px;
  border: none;
  background-color: #f7f4f4;
  color: #070707;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-color:#333;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.specials-subnav button:hover {
  background-color: #e8e8e8;
  color: #333;
}

.specials-subnav button.active {
  background-color: #3498db;
  color: white;
  box-shadow: 0 2px 4px rgba(52, 152, 219, 0.2);
}

/* Optional: Add a subtle animation when switching tabs */
.specials-content,
.book-event-content,
.live-music-content {
  animation: fadeIn 0.3s ease-in-out;
  max-width: 100%;
  margin: 0 auto;
  max-width: 600px;
  width:100%
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Make sure sub-nav stands out from content */
.specials-events-tab {
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  margin: 0 auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .specials-subnav {
    padding: 10px;
    gap: 7px;
  }

  .specials-subnav button {
    padding: 6px 15px;
    font-size: 13px;
  }
}


.event-section {
  width: 100%;
  max-width: 600px; /* Match the parent container width */
  margin: 0 auto; /* This will center the section */
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  box-sizing: border-box;
}

.event-options {
  width: 100%;
  max-width: 600px; /* Adjust this value as needed */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
  margin-top: 15px;
  box-sizing: border-box;
}

.event-option {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden; /* Add this to contain children */
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border-radius: 6px;
  padding: 15px;
  gap: 10px;
}

.option-file {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Add this to contain image overflow */
}

.option-file img {
  width: auto;
  height: auto;
  max-width: 100%; /* Ensure image doesn't overflow */
  object-fit: contain; /* Change from cover to contain */
  border-radius: 4px;
  display: block; /* Prevent inline image spacing issues */
  margin: 0 auto;
}

.live-event {
  background: white;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.event-date {
  color: #666;
  font-size: 0.9em;
  margin: 10px 0;
}

.book-option-btn,
.book-event-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: auto;
}

.book-option-btn:hover,
.book-event-btn:hover {
  background-color: #45a049;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.event-details {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.error-message {
  color: #dc3545;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fde8e8;
  border-radius: 4px;
}

.success-message {
  color: #28a745;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #e8f5e9;
  border-radius: 4px;
}

.special-requests {
  width: 100%;
  min-height: 100px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.event-file img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover; /* This will ensure the image covers the area nicely */
}

.live-event {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.live-event h3 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
}

.live-event .event-date {
  color: #2196F3; /* Blue color for emphasis */
  font-weight: 600;
  font-size: 1.1rem;
  padding: 8px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.live-event .event-description {
  color: #666;
  line-height: 1.5;
}

.event-file {
  width: 100%;
  margin: 10px 0;
}


.book-event-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  align-self: flex-start; /* Aligns button to the left */
}

.book-event-btn:hover {
  background-color: #45a049;
}

.no-events {
  text-align: center;
  color: #666;
  padding: 20px;
  font-style: italic;
}

/* Remove grid layout for desktop */
@media (min-width: 768px) {
  .live-event {
    max-width: 600px; /* Optional: limit width on larger screens */
    margin-left: auto;
    margin-right: auto;
  }

  .event-file img {
    height: 300px; /* Slightly larger images on desktop */
  }
}

/* Booking Modal Updates */
.modal-content.booking-modal {
  width: 95%;
  max-width: 350px;
  padding: 20px;
  margin: 20px;
}

@media (max-width: 480px) {
  .modal-content.booking-modal {
    margin: 10px;
    padding: 15px;
  }

  .button-group {
    display: flex;
    gap: 10px;
    flex-direction: row;
  }

  .button-group button {
    flex: 1;
    padding: 12px;
    font-size: 14px;
  }
}

.cancel-btn {
  height:40px !important;
}

/* Loading State for Booking Modal */
.booking-modal .submit-btn {
  position: relative;
  height:40px;
  margin:0 auto;
  max-width: 200px !important;
}

.booking-modal .submit-btn.loading {
  background-color: #cccccc;
  pointer-events: none;
}

.booking-modal .submit-btn.loading::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -10px;
  border: 2px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: button-loading 0.8s linear infinite;
}

@keyframes button-loading {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.cancel-btn{
  height:40px;
  margin:0 auto
}

.redeem-voucher-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  max-width: 200px;
  transition: background-color 0.3s ease;
}

.redeem-voucher-btn:hover {
  background-color: #45a049;
}

.redeem-voucher-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.ios-install-prompt {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  padding: 20px;
  overflow: hidden; /* Prevent background scroll */
}

.ios-install-content {
  background: white;
  padding: 30px;
  border-radius: 15px;
  max-width: 90%;
  width: 400px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  overflow-y: auto; /* Enable scroll for content */
  -webkit-overflow-scrolling: touch; /* Smooth scroll on iOS */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.install-steps {
  margin: 20px 0;
  padding-left: 20px;
}

.install-steps li {
  margin-bottom: 15px;
  line-height: 1.4;
  color: #333;
}

.install-guide-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin: 20px auto;
  display: block;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.understand-button {
  width: 100%;
  padding: 12px;
  background-color: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.understand-button:hover {
  background-color: #0056b3;
}

.vtitle{
  font-size: 15px;
  color: #666;
}

.share-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  color: #333;
  position: relative;
  top:25px;
  left:25px
}


.share-text {
  margin-left: 5px;
  font-size: 14px;
}


.header-buttons {
  display: flex;
  align-items: center;
}

.action-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.register-btn {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-btn:hover {
  background-color: #2980b9;
}

.welcome-message {
  color: #2c3e50;
  font-size: 1em;
  line-height: 1.4;
  margin: 15px 0;
  font-weight: 500;
}


/* Safari-specific fixes */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* Fix for special images in Safari */
  .special-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .special-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin: 10px auto;
    overflow: hidden;
  }

  /* Fix for profile button in Safari */

  .profile-button .fa-user {
    width: 24px;
    height: 24px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  /* Additional fixes for image rendering */
  .special-item img {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    max-width: 100%;
    height: auto;
  }
}

/* Additional Safari-only fixes */
_::-webkit-full-page-media, _:future, :root {
  .special-image-container {
    width: 100%;
    max-width: 300px;
    margin: 10px auto;
  }

  .special-image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  
}


.review-cta {
  display: flex;
  justify-content: flex-end;
}

.review-button {
  color: #2980b9;
  font-size: 16px;
  font-weight: 500;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  position: relative;
}

.review-button::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #2980b9;
  transition: width 0.3s ease-in-out;
}

.review-button:hover::after {
  width: 100%;
}

.review-button:hover {
  background-color: transparent;
}