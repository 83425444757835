.admin-dashboard {
    padding: 20px;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .dashboard-header h1 {
    margin: 0;
    color: #2c3e50;
  }
  
  .refresh-info {
    color: #666;
    font-size: 0.9rem;
  }
  
  .dashboard-nav {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    background: #f8f9fa;
    padding: 10px;
    border-radius: 8px;
  }
  
  .dashboard-nav button {
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    border-radius: 4px;
    color: #666;
    transition: all 0.3s ease;
  }
  
  .dashboard-nav button.active {
    background: #3498db;
    color: white;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .stat-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .stat-card h3 {
    margin: 0 0 10px 0;
    color: #666;
    font-size: 0.9rem;
  }
  
  .stat-number {
    font-size: 2rem;
    font-weight: bold;
    color: #2c3e50;
    margin: 0;
  }
  
  .table-container {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  th {
    background: #f8f9fa;
    color: #666;
    font-weight: 600;
  }
  
  .status {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  .status.success {
    background: #d4edda;
    color: #155724;
  }
  
  .status.failure {
    background: #f8d7da;
    color: #721c24;
  }
  
  .settings-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .setting-group {
    margin-bottom: 20px;
  }
  
  .setting-group label {
    display: block;
    margin-bottom: 8px;
    color: #666;
  }
  
  .setting-group select {
    width: 200px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.2rem;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .stats-grid {
      grid-template-columns: 1fr;
    }
  
    .dashboard-nav {
      flex-direction: column;
    }
  
    .dashboard-nav button {
      width: 100%;
      text-align: left;
    }
  
    .table-container {
      margin: 0 -20px;
      border-radius: 0;
    }
  }

  .sessions-container {
    margin-top: 20px;
  }
  
  .status-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin: 20px 0;
  }
  
  .status-item {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .status-item h4 {
    margin: 0 0 10px 0;
    color: #666;
  }
  
  .status-item p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
  }
  
  .user-agent {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .status.success {
    color: #28a745;
  }
  
  .status.failure {
    color: #dc3545;
  }

  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .header-controls {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .logout-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .logout-btn:hover {
    background-color: #c0392b;
  }
  
  /* Make sure the logout button is visible on mobile */
  @media (max-width: 768px) {
    .dashboard-header {
      flex-direction: column;
      gap: 10px;
    }
  
    .header-controls {
      width: 100%;
      justify-content: space-between;
    }
  
    .logout-btn {
      padding: 8px 16px;
      font-size: 12px;
    }
  }

  /* test account styles */
.test-accounts-manager {
  padding: 20px;
}

.search-section {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.search-section input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.test-accounts-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.test-account-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 8px;
}

.remove-test-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
}

.results-grid {
  margin-top: 10px;
  margin-bottom: 20px;
}

/* In AdminDashboard.css */
.result-info {
  display: flex;
  flex-direction: column;
}

.result-info small {
  color: #666;
  font-size: 0.8em;
}

.mark-test-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.mark-test-btn:hover {
  background-color: #45a049;
}


.search-results {
  margin: 20px 0;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 4px;
}