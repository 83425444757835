.customer-dashboard {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1, h2 {
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .logout-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    float: right;
  }
  
  .program-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .program-card h3 {
    color: #3498db;
    margin-bottom: 10px;
  }
  
  .program-card p {
    margin-bottom: 5px;
  }
  
  .no-programs {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    color: #7f8c8d;
  }