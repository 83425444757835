.general-details {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .general-details h2 {
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  .general-details form > div {
    margin-bottom: 15px;
  }
  
  .general-details label {
    display: block;
    margin-bottom: 5px;
    color: #34495e;
  }
  
  .general-details input[type="text"],
  .general-details input[type="tel"],
  .general-details textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
  }
  
  .general-details textarea {
    height: 100px;
  }
  
  .general-details button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    max-width: 300px;
  }
  
  .general-details button:hover {
    background-color: #2980b9;
  }
  
  .image-slides {
    margin-top: 30px;
  }
  
  .image-slides h3 {
    margin-bottom: 15px;
    color: #2c3e50;
  }
  
  .slides-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .slide-item {
    width: 200px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .slide-item img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .delete-slide-btn {
    background-color: #ff4d4d !important; 
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-slide-btn:hover {
    background-color: #e60000;
  }
  
  .preview-image {
    max-width: 200px;
    max-height: 200px;
    margin-top: 10px;
  }
  
  .add-slide-btn {
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group img {
    display: block;
    max-width: 200px;
    max-height: 200px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.2em;
    color: #333;
  }

  .redemption-settings {
    margin-top: 20px;
  }
  
  .redemption-settings label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
  }
  
  .redemption-settings input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin: 5px;
  }
  
  .redemption-settings .pin-input {
    margin-top: 10px;
  }
  
  .redemption-settings .pin-input input {
    padding: 8px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    width: 100px;
  }
  
  .redemption-settings .pin-info {
    font-size: 14px;
    color: #7f8c8d;
    margin-top: 5px;
  }
  
  .redemption-settings .save-settings-btn {
    margin-top: 15px;
  }