.all-vendors {
    padding: 20px;
  }
  
  .vendor-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .vendor-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .view-programs-btn {
    display: inline-block;
    background-color: #3498db;
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 4px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .view-programs-btn:hover {
    background-color: #2980b9;
  }