.subscription-result-container {
  max-width: 600px;
  margin: 60px auto;
  padding: 40px;
  text-align: center;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.subscription-success-message,
.subscription-error-message {
  padding: 30px;
  border-radius: 8px;
}

.subscription-success-message {
  background-color: #f0fff4;
  border: 1px solid #c6f6d5;
}

.subscription-error-message {
  background-color: #fff5f5;
  border: 1px solid #fed7d7;
}

.subscription-success-icon,
.subscription-error-icon {
  font-size: 48px;
  margin-bottom: 20px;
}

.subscription-success-icon {
  color: #48bb78;
}

.subscription-error-icon {
  color: #e53e3e;
}

.subscription-result-container h1 {
  color: #2c3e50;
  font-size: 2rem;
  margin-bottom: 15px;
}

.subscription-result-container p {
  color: #4a5568;
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 25px;
}

.subscription-error-actions {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin: 25px 0;
}

.subscription-retry-button,
.subscription-dashboard-button {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscription-retry-button {
  background-color: #3498db;
  color: white;
}

.subscription-retry-button:hover {
  background-color: #2980b9;
}

.subscription-dashboard-button {
  background-color: #718096;
  color: white;
}

.subscription-dashboard-button:hover {
  background-color: #4a5568;
}

.subscription-details-summary {
  margin-top: 30px;
  text-align: left;
  background: #f7fafc;
  padding: 20px;
  border-radius: 8px;
}

.subscription-details-summary h3 {
  color: #2d3748;
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.subscription-details-summary ul {
  list-style: none;
  padding: 0;
}

.subscription-details-summary li {
  padding: 8px 0;
  color: #4a5568;
  display: flex;
  align-items: center;
}

.subscription-details-summary li::before {
  content: "✓";
  color: #48bb78;
  margin-right: 10px;
}

.subscription-support-text {
  margin-top: 20px;
  color: #718096;
  font-size: 0.9rem;
}

.subscription-support-text a {
  color: #3498db;
  text-decoration: none;
}

.subscription-support-text a:hover {
  text-decoration: underline;
}

@media (max-width: 640px) {
  .subscription-result-container {
      margin: 20px;
      padding: 20px;
  }

  .subscription-error-actions {
      flex-direction: column;
  }

  .subscription-error-actions button {
      width: 100%;
  }
}