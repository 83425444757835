.landing-page-body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    background: linear-gradient(to bottom, #f0f9ff 0%, #ffffff 100%);
  }
  
  .ml-landing-page {
    width: 100vw;
    overflow-x: hidden;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  }
  
  /* Header & Navigation */
  .ml-header {
    background-color: #ffffff;
    padding: 0.75rem 1rem;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    width: 100%;
  }
  
  .ml-nav {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1rem;
    width:95%;
  }
  
  .ml-nav-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .ml-nav-left {
    display: flex;
    align-items: center;
  }
  
  .ml-logo-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .ml-logo {
    height: auto;
    width: 220px;
    padding-right: 0.5rem;
    position: relative;
    top: 0.6rem;
  }
  
  .ml-logo-text {
    font-size: 1.25rem;
    font-weight: 600;
    color: #374151;
    text-decoration: none;
  }
  
  .ml-nav-menu {
    display: flex;
    gap: 2rem;
  }
  
  .ml-nav-link {
    color: #4B5563;
    text-decoration: none;
    font-size: 0.95rem;
    font-weight: 500;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid transparent;
    transition: all 0.2s ease;
  }
  
  .ml-nav-link:hover {
    color: #0284c7;
    border-bottom-color: #0284c7;
  }
  
  .ml-nav-cta .ml-sign-up-btn {
    background: #0284c7;
    color: #ffffff;
    padding: 0.625rem 1.25rem;
    border-radius: 0.375rem;
    font-weight: 500;
    font-size: 0.95rem;
    text-decoration: none;
    transition: background-color 0.2s ease;
  }
  
  .ml-nav-cta .ml-sign-up-btn:hover {
    background: #0369a1;
  }
  
  /* Hero Section */
  .ml-hero {
    position: relative;
    padding: 4rem 1rem;
    max-width: 1280px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    align-items: center;
  }
  
  .ml-hero-gradient {
    position: absolute;
    inset: 0;
    opacity: 0.1;
    pointer-events: none;
  }
  
  .ml-hero-text {
    max-width: 32rem;
  }
  
  .ml-hero-title {
    font-size: 3.75rem;
    line-height: 1.1;
    font-weight: 800;
    color: #1F2937;
    margin: 0;
  }
  
  .ml-hero-title .ml-highlight {
    color: #0284c7;
    display: block;
  }
  
  .ml-hero-description {
    margin-top: 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
    color: #4B5563;
  }
  
  .ml-hero-cta {
    margin-top: 2rem;
  }
  
  .ml-cta-button {
    display: inline-block;
    background: #0284c7;
    color: #ffffff;
    padding: 0.875rem 1.75rem;
    border-radius: 0.375rem;
    font-weight: 500;
    text-decoration: none;
    transition: background-color 0.2s ease;
    font-size: 1rem;
  }
  
  .ml-cta-button:hover {
    background: #0369a1;
  }
  
  .ml-hero-image {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .ml-hero-image img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
  }
  
  /* Media Queries */
  @media (max-width: 1024px) {
    .ml-hero-title {
      font-size: 3rem;
      text-align:center;
    }
  }
  
  @media (max-width: 768px) {

    .ml-nav-cta .ml-sign-up-btn {
        display: none;
      }

    .ml-nav-menu {
      display: none;
    }
  
    .ml-hero {
      grid-template-columns: 1fr;
      text-align: center;
      padding: 3rem 1rem;
    }
  
    .ml-hero-text {
      max-width: 100%;
    }
  
    .ml-hero-title {
      font-size: 2.5rem;
    }
  
    .ml-hero-description {
      margin-left: auto;
      margin-right: auto;
      max-width: 32rem;
    }
  
    .ml-hero-image {
      margin-top: 2rem;
    }
  }
  
  @media (max-width: 640px) {
    .ml-hero {
      padding: 2rem 1rem;
    }
  
    .ml-hero-title {
      font-size: 2rem;
    }
  
    .ml-hero-description {
      font-size: 1.115rem;
    }
  
    .ml-logo-text {
      font-size: 1.125rem;
      text-decoration: none;
    }
  }
  
  /* Features Section */
  .ml-features {
    padding: 5rem 1rem;
    background: #ffffff;
  }
  
  .ml-features-header {
    max-width: 48rem;
    margin: 0 auto;
    text-align: center;
  }
  
  .ml-section-tag {
    color: #0284c7;
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0.75rem;
    text-align:center;
  }
  
  .ml-section-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #1F2937;
    margin: 0.5rem 0;
    line-height: 1.2;
    text-align:center;
  }
  
  .ml-section-description {
    font-size: 1.25rem;
    color: #6B7280;
    margin-top: 1rem;
    text-align:center;
  }
  
  .ml-features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1280px;
    margin: 3rem auto 0;
    padding: 0 1rem;
  }
  
  .ml-feature-card {
    padding: 1.5rem;
    background: #F9FAFB;
    border-radius: 0.5rem;
    transition: transform 0.2s ease;
  }
  
  .ml-feature-card:hover {
    transform: translateY(-4px);
  }
  
  .ml-feature-icon {
    width: 3rem;
    height: 3rem;
    background-color: #0284c7;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .ml-feature-icon svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .ml-feature-title {
    margin-top: 1rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: #1F2937;
  }
  
  .ml-feature-description {
    margin-top: 0.5rem;
    color: #6B7280;
    line-height: 1.5;
    font-size: 1.1rem;
  }
  
  /* How It Works Section */
  .ml-how-it-works {
    padding: 5rem 1rem;
    background-color: #F9FAFB;
  }
  
  .ml-steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5rem;
    max-width: 1280px;
    margin: 3rem auto 0;
  }
  
  .ml-step {
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    text-align: center;
  }
  
  .ml-step-number {
    width: 3rem;
    height: 3rem;
    background-color: #0284c7;
    color: white;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 auto 1rem;
  }
  
  .ml-step-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1F2937;
    margin-bottom: 0.5rem;
  }
  
  .ml-step-description {
    color: #6B7280;
    line-height: 1.5;
    font-size: 1.1rem
  }
  
  /* Pricing Section */
  .ml-pricing {
    padding: 5rem 1rem;
    background-color: #ffffff;
  }
  
  .ml-pricing-plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    max-width: 1280px;
    margin: 3rem auto 0;
  }
  
  .ml-plan-card {
    flex: 1;
    min-width: 280px;
    max-width: 320px;
    padding: 2rem;
    background: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    transition: transform 0.2s ease;
  }
  
  .ml-plan-card:hover {
    transform: translateY(-4px);
  }
  
  .ml-plan-card.ml-pro {
    border: 2px solid #0284c7;
  }
  
  .ml-plan-header h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1F2937;
  }
  
  .ml-plan-price {
    margin: 1rem 0;
    font-size: 3rem;
    font-weight: 800;
    color: #1F2937;
  }
  
  .ml-plan-price span {
    font-size: 1.25rem;
    font-weight: 500;
    color: #6B7280;
  }
  
  .ml-plan-features {
    margin: 1.5rem 0;
    list-style: none;
    padding: 0;
  }
  
  .ml-plan-features li {
    margin: 0.75rem 0;
    padding-left: 1.75rem;
    position: relative;
    color: #6B7280;
  }
  
  .ml-plan-features li::before {
    content: "✓";
    position: absolute;
    left: 0;
    color: rgb(34 197 94 / var(--tw-text-opacity, 1));
    font-weight: bold;
  }
  
  .ml-plan-cta {
    display: block;
    width: 75%;
    padding: 0.75rem 1.5rem;
    background: #0284c7;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 0.375rem;
    font-weight: 500;
    transition: background-color 0.2s ease;
    margin: 0 auto;
  }
  
  .ml-plan-cta:hover {
    background: #0369a1;
  }
  
  /* Final CTA Section */
  .ml-final-cta {
    padding: 4rem 1rem;
    background-color: #0284c7;
    text-align: center;
    color: white;
  }
  
  .ml-final-cta h2 {
    font-size: 2.25rem;
    font-weight: 800;
    margin-bottom: 1rem;
    color:white
  }
  
  .ml-final-cta p {
    font-size: 1.25rem;
    color: rgba(255,255,255,0.9);
    margin-bottom: 2rem;
  }
  
  .ml-cta-button-white {
    display: inline-block;
    padding: 0.875rem 1.75rem;
    background: white;
    color: #0284c7;
    text-decoration: none;
    border-radius: 0.375rem;
    font-weight: 500;
    transition: transform 0.2s ease, background-color 0.2s ease;
  }
  
  .ml-cta-button-white:hover {
    background: #f3f4f6;
    transform: translateY(-2px);
  }
  
  /* Footer */
  .ml-footer {
    background-color: white;
    padding: 2rem 1rem;
    border-top: 1px solid #E5E7EB;
  }
  
  .ml-footer-content {
    max-width: 1280px;
    margin: 0 auto;
    text-align: center;
    color: #6B7280;
  }
  
  /* Additional Media Queries */
  @media (max-width: 640px) {
    .ml-section-title {
      font-size: 2rem;
    }
    
    .ml-features-grid {
      grid-template-columns: 1fr;
    }
    
    .ml-plan-card {
      width: 100%;
    }
    
    .ml-final-cta h2 {
      font-size: 1.75rem;
    }
    
    .ml-final-cta p {
      font-size: 1rem;
    }

    .whatsapp-button {
        padding: 10px 10px !important;
    }

  }

  .ml-logo-link{
  text-decoration: none;
  }

  .ml-hero-content {
    flex: 1;
    }

.ml-main {
    padding:5px
}

.ml-nav-cta {
    padding-right:5px
}


.whatsapp-button-lp {
    display: inline-flex;
    align-items: center;
    background-color: #25D366;
    color: white;
    z-index:9999;
    border-radius: 30px;
    text-decoration: none;
    margin-top: 20px;
    font-weight: bold;
    z-index: 9999;
}

.whatsapp-button-lp img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.whatsapp-button-lp {
    display: inline-flex;
    align-items: center;
    background-color: #25D366;
    color: white;
    z-index: 9999;
    border-radius: 30px;
    text-decoration: none;
    margin-top: 20px;
    font-weight: bold;
  }
  
  .whatsapp-button-lp i {
    font-size: 24px;
    margin-right: 10px;
  }

  .whatsapp-button {
      z-index: 9999;
      border-radius: 25px;
      height: auto;
      padding: 10px 20px;
      width: auto;
  }
