.info-icon {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color:transparent !important;
  }
  

  .info-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;         
    height: 20px;      
    border-radius: 50%;
    border: 1px solid #333;  
    background-color: transparent !important;
    color: #333;
    font-size: 14px;  
    font-weight: bold;
    font-style: italic;
}
  
  /* Remove or modify hover effect */
  .info-icon:hover .info-circle {
    background-color:transparent !important;
    color: #666; 
  }
  
  /* Update vendor and customer variants */
  .info-icon.vendor .info-circle {
    background-color: transparent;
    color: #2ecc71;
  }
  
  .info-icon.customer .info-circle {
    background-color: transparent;
    color: #e74c3c;
  }

  .tooltip-content {
    position: absolute;
    background-color: #333;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 14px;
    width: max-content;
    max-width: 250px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    /* Remove opacity and animation properties */
  }


  .info-tooltip-container {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  .info-icon {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    position: relative; /* Add this */
  }
  
  .info-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;         
    height: 20px;      
    border-radius: 50%;
    border: 1px solid #333;  
    background-color: transparent !important;
    color: #333;
    font-size: 14px;  
    font-weight: bold;
    font-style: italic;
    transition: all 0.2s ease;
  }
  
  /* Variants */
  .info-icon.vendor .info-circle {
    border-color: #2ecc71;
    color: #2ecc71;
  }
  
  .info-icon.customer .info-circle {
    border-color: #e74c3c;
    color: #e74c3c;
  }
  
  .info-icon.light .info-circle {
    border-color: #666;
    color: #666;
  }
  
  /* Tooltip Content Styles */
  .tooltip-content {
    position: absolute;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.4;
    z-index: 1000;
    width: 280px; /* Fixed width */
    max-width: 90vw; /* Responsive max-width */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.2s ease, transform 0.2s ease;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
  }
  
  /* Dark Theme (default) */
  .tooltip-content.dark {
    background-color: #333;
    color: white;
  }
  
  /* Light Theme */
  .tooltip-content.light {
    background-color: #fff;
    color: #333;
    border: 1px solid #e1e1e1;
  }
  
  /* Position Variants */
  .tooltip-content.top {
    bottom: calc(100% + 10px);
  }
  
  .tooltip-content.right {
    left: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
  }
  
  .tooltip-content.bottom {
    top: calc(100% + 10px);
  }
  
  .tooltip-content.left {
    right: calc(100% + 10px);
    left: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .tooltip-content {
      position: fixed;
      bottom: 0 !important;
      left: 0 !important;
      right: 0 !important;
      top: auto !important;
      transform: none !important;
      width: 100% !important;
      max-width: 100% !important;
      margin: 0;
      border-radius: 12px 12px 0 0;
      padding: 20px;
      animation: slideUp 0.3s ease-out;
    }
  
    .tooltip-content.light {
      background-color: #f8f9fa;
      border-bottom: none;
    }
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }


  .info-tooltip-container {
    position: relative;
    display: inline-flex;
    align-items: center;
  }
  
  /* ... (previous styles remain the same) ... */
  
  /* Add close button styles */
  .tooltip-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s ease;
    padding: 0;
  }
  
  .tooltip-close:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .tooltip-close::before,
  .tooltip-close::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 2px;
    background-color: #666;
    transform-origin: center;
  }
  
  .tooltip-close::before {
    transform: rotate(45deg);
  }
  
  .tooltip-close::after {
    transform: rotate(-45deg);
  }
  
  /* Adjust padding for light theme to accommodate close button */
  .tooltip-content.light {
    padding-right: 36px;
  }
  
  /* Mobile adjustments for close button */
  @media (max-width: 768px) {
    .tooltip-close {
      top: 12px;
      right: 12px;
      width: 28px;
      height: 28px;
    }
  
    .tooltip-close::before,
    .tooltip-close::after {
      width: 14px;
    }
  }
  